import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import ApiError from '../../errors/ApiError';

const style = {
  marginLeft: '20px',
  marginRight: '20px',
};

const invalidJson = '{hello';

const FakeErrorButton = ({ children, technique }) => {
  const [throwAnError, setThrowAnError] = useState(false);

  if (throwAnError) {
    switch (technique) {
      case 'SyntaxError':
        return JSON.parse(invalidJson);
      case 'Error':
        throw new Error('This is a fake error triggered by pressing the fake error button with a bomb on it!');
      case 'ApiError':
        throw new ApiError('This is a fake error triggered by pressing the fake error button with a bomb on it!');
      default:
        throw new Error('undefined');
    }
  }

  return (
    <Button
      style={style}
      variant="contained"
      color="secondary"
      onClick={() => setThrowAnError(true)}
    >
      {children}
    </Button>
  );
};

FakeErrorButton.defaultProps = {
  technique: 'SyntaxError',
};

FakeErrorButton.propTypes = {
  children: PropTypes.node.isRequired,
  technique: PropTypes.string,
};

export default FakeErrorButton;
