import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField } from '@material-ui/core';

const TextFieldComponent = ({
  fullWidth, variant, ...rest
}) => (
  <FormControl fullWidth={fullWidth}>
    <TextField
      variant={variant}
      {...rest}
    />
  </FormControl>
);

TextFieldComponent.defaultProps = {
  fullWidth: false,
  variant: undefined,
};

TextFieldComponent.propTypes = {
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default TextFieldComponent;
