import {
  createStore as createReduxStore,
  applyMiddleware,
  compose,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import reducers from '../reducers';

/**
 * The single redux store for the app
 *
 * @var {Store}
 */
let store = null;

/**
 * Creates a new Redux Store, using the provided history.
 *
 * IMPORTANT: May be called more than once (in microfrontend form);
 * in this case, return the same store.
 *
 * When used in real app, history should be createBrowserHistory(), but for
 * testing should use createMemoryHistory(), from the history module.
 *
 * @param   {History}  history The history to use.
 *
 * @return  {Store} The new store
 */
export default function createStore(history) {
  if (store) return store;

  store = createReduxStore(reducers(history), compose(
    applyMiddleware(
      thunk,
      routerMiddleware(history),
    ),
    /* eslint-disable-next-line no-underscore-dangle */
    process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ));

  return store;
}

/**
 * Retrieves the store previously created by createStore. Will throw an Error if
 * createStore() has not been called yet.
 *
 * @return  {Store}  The store
 */
export function getStore() {
  if (!store) throw new Error('getStore() called prior to createStore()');
  return store;
}

/**
 * For use in tests that make use of the real store rather than a mock one,
 * resets the store so createStore can be called again.
 */
export function resetStore() {
  if (process.env.NODE_ENV !== 'test') throw new Error('resetStore() should only be called in test environment');
  store = null;
}
