import callBackendApi from './backend';
import { objectHasAll, logger } from '../helpers';

// Can be empty/blank
const REQUIRED_CREATE_MAILING_FIELDS = [
  'name',
  'state',
  'election',
  'subject',
  'body',
  'shiftTypes',
  'audienceTypes',
  'electorates',
  'senderName',
  'senderEmail',
  'replyEmail',
  'active',
];

const REQUIRED_TEST_MAILING_FIELDS = [
  'senderName',
  'senderEmail',
  'subject',
  'body',
  'testEmailAddresses',
];

const REQUIRED_UPDATE_MAILING_FIELDS = REQUIRED_CREATE_MAILING_FIELDS;

export const createMailing = async (token, values) => {
  logger.debug('createMailing() called', { values });

  const hasAllRequired = objectHasAll(values, REQUIRED_CREATE_MAILING_FIELDS);
  if (!hasAllRequired) throw new Error('Missing one or more required fields (must all be set, but can be blank/empty)');

  return callBackendApi(token, 'post', '/mailings', values);
};

export const updateMailing = async (token, mailingId, values) => {
  logger.debug('updateMailing() called', { mailingId, values });

  const hasAllRequired = objectHasAll(values, REQUIRED_UPDATE_MAILING_FIELDS);
  if (!hasAllRequired) throw new Error('Missing one or more required fields (must all be set, but can be blank/empty)');

  return callBackendApi(token, 'put', `/mailings/${mailingId}`, values);
};

export const getMailings = async (token) => {
  logger.debug('getMailings() called', { });

  const { data } = await callBackendApi(token, 'get', '/mailings');
  return data;
};

export const getMailing = async (token, mailingId) => {
  logger.debug('getMailing() called', { mailingId });

  const { data } = await callBackendApi(token, 'get', `/mailings/${mailingId}`);
  return data;
};

export const deleteMailing = async (token, mailingId) => {
  logger.debug('deleteMailing() called', { mailingId });

  const { data } = await callBackendApi(token, 'delete', `/mailings/${mailingId}`);
  return data;
};

export const getElections = async (token, stateCode) => {
  logger.debug('getElections() called', { stateCode });

  if (!stateCode) return [];

  const { data } = await callBackendApi(token, 'get', `/elections?state=${stateCode}`);
  return data;
};

export const getRecipients = async (token, mailingId) => {
  logger.debug('getRecipients() called', { mailingId });

  const { data } = await callBackendApi(token, 'get', `/mailings/${mailingId}/recipients`);
  return data;
};

export const getDeliveries = async (token, mailingId) => {
  logger.debug('getDeliveries() called', { mailingId });

  const { data } = await callBackendApi(token, 'get', `/mailings/${mailingId}/deliveries`);
  return data;
};

export const getMailingTemplates = async (token) => {
  logger.debug('getMailingTemplates() called', { });

  const { data } = await callBackendApi(token, 'get', '/mailingtemplates');
  return data;
};

export const testMailing = async (token, values) => {
  logger.debug('testMailing() called', { values });

  const hasAllRequired = objectHasAll(values, REQUIRED_TEST_MAILING_FIELDS);
  if (!hasAllRequired) throw new Error('Missing one or more required fields (must all be set, but can be blank/empty)');

  return callBackendApi(token, 'post', '/testmailing', values);
};
