import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { push } from 'connected-react-router';
import MailingForm from '../MailingForm';
import {
  updateExistingMailing, resetRecipients, resetDeliveries, getSingleMailing,
} from '../../actions';
import Breadcrumb from '../Breadcrumb';
import { useSaladbar } from '../../saladbar';
import {
  FORM_SUBMISSION_REASON,
  determineUpdateMailingFormSubmissionReason,
  mailingFormSubmissionMessage,
} from '../MailingForm/helpers/submission';
import ErrorPopin from '../ErrorPopin';
import { SENDING_NOTIFICATION_PROGRESS_INDICATOR } from '../../constants';
import isResetRecipientsAndDelivery from './helpers/isResetRecipientsAndDelivery';
import { microfrontendURLPrefix } from '../../config';

const MailingView = (props) => {
  const {
    match: { params: { mailingId } },
  } = props;

  const { user, getAccessTokenSilently } = useAuth0();
  const {
    enqueueInfoNotification,
    enqueueSuccessNotification,
    enqueueErrorNotification,
    removeNotification,
  } = useSaladbar();

  /** @type {Promise} */
  const dispatch = useDispatch();

  const [mailingInfo, setMailingInfo] = useState(undefined);
  const updateMailingStatus = useSelector((state) => state.mailing.updateMailingStatus);
  const updateMailingFailReason = useSelector((state) => state.mailing.updateMailingFailReason);
  // eslint-disable-next-line no-unused-vars
  const getSingleMailingData = useSelector((state) => state.mailing.getSingleMailing);
  const openOnTab = useSelector((state) => {
    const hash = state?.router?.location?.hash;
    return (hash === undefined || hash === '') ? undefined : hash.substring(1);
  });
  const [errorMessage, setErrorMessage] = useState('');

  const formSubmitHandler = async (values, initialValues, formik) => {
    // Formik will set isSubmitting=false once this handler resolves.
    const sanitisedValues = {
      ...values,
      scheduleStart: values.scheduleStart ?? '',
      scheduleStop: values.scheduleStop ?? '',
      scheduleOnce: values.scheduleOnce ?? '',
      createdByName: user.name,
      createdByEmail: user.email,
      createdBySub: user.sub,
    };

    const reason = determineUpdateMailingFormSubmissionReason(sanitisedValues, initialValues);
    let sendingNotificationKey;
    if (reason === FORM_SUBMISSION_REASON.SEND_ONLY_ONCE) {
      sendingNotificationKey = enqueueInfoNotification('Sending...', {
        autoHideDuration: null,
        progressIndicator: SENDING_NOTIFICATION_PROGRESS_INDICATOR,
      });
    }

    try {
      const token = await getAccessTokenSilently();
      await dispatch(updateExistingMailing(token, mailingId, sanitisedValues));

      enqueueSuccessNotification(
        mailingFormSubmissionMessage(
          'update',
          true,
          sanitisedValues,
          initialValues,
          null,
          reason,
        ),
      );

      // Also reset the recipients, to force the list to be reloaded since they
      // may have changed.

      if (isResetRecipientsAndDelivery(initialValues, values)) {
        await dispatch(resetRecipients());
        await dispatch(resetDeliveries());
      }

      // Must reset the form, passing in the current values as the new initial
      // values. This ensures it does not remain dirty after submission.
      // https://bleepcoder.com/formik/538478066/reset-dirty-property-after-submit
      // We don't use the sanitisedValues with strings, we want nulls for dates
      formik.resetForm({ values });

      // Also set the mailingInfo so the previousValues etc are up to date
      setMailingInfo(sanitisedValues);
    } catch (err) {
      enqueueErrorNotification(
        mailingFormSubmissionMessage(
          'update',
          false,
          sanitisedValues,
          initialValues,
          err,
          reason,
        ),
      );
    } finally {
      // We wait until we've queued the next notification before removing the old one
      if (sendingNotificationKey) removeNotification(sendingNotificationKey);
    }
  };

  useEffect(() => {
    const startGetSingleMailing = async () => {
      const token = await getAccessTokenSilently();
      if (Number.isInteger(Number(mailingId, 10))) {
        dispatch(getSingleMailing(token, mailingId));
      } else {
        setErrorMessage('mailing not an integer');
      }
    };

    startGetSingleMailing();
  }, [dispatch, mailingId, getAccessTokenSilently]);

  useEffect(() => {
    const mailing = { ...getSingleMailingData };
    const otherMailingData = async () => {
      if (mailing?.errors?.length
          && mailing.errors[0].name === 'EntityNotFoundError') {
        setErrorMessage(mailing.errors[0].message);
      } else if (mailing?.electorates) {
        mailing.electorates = mailing
          ?.electorates?.map((x) => x?.id || x) ?? [];
        setMailingInfo(mailing);
      }
    };
    otherMailingData();
  }, [getSingleMailingData, getAccessTokenSilently]);

  return (
    <>
      <Breadcrumb
        parents={
        [{
          path: microfrontendURLPrefix || '/',
          label: 'Mailings',
        }]
        }
        currentLabel={mailingInfo ? mailingInfo.name : ''}
      />
      { mailingInfo && !errorMessage && (
        <MailingForm
          formInitialValues={mailingInfo}
          formSubmitHandler={formSubmitHandler}
          mailingStatus={updateMailingStatus}
          mailingFailReason={updateMailingFailReason}
          openOnTab={openOnTab}
          cleanSaveEnable={false}
          isNewObject={false}
          copyOriginalId={0}
          mailingId={mailingId}
        />
      )}
      { !mailingInfo && errorMessage && (
      <ErrorPopin
        title="The page cannot be found"
        message="The URL you requested does not exist"
        handler={() => dispatch(push(`${microfrontendURLPrefix}/mailings`))}
        button="Home"
      />
      )}
    </>
  );
};

MailingView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      mailingId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default MailingView;
