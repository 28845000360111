import React from 'react';
import styles from './styles.module.scss';

const spinnerImg = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

const Spinner = () => (
  <div className={styles.loadingSpinner}>
    <img src={spinnerImg} alt="Loading..." />
  </div>
);

export default Spinner;
