import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const Breadcrumb = ({ parents, currentLabel }) => {
  const dispatch = useDispatch();
  const handleBreadcrumb = (path) => {
    dispatch(push(path));
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        {parents.map((parent) => (
          <Link
            color="inherit"
            key={parent.path}
            href={parent.path}
            onClick={(event) => {
              event.preventDefault();
              handleBreadcrumb(parent.path);
            }}
          >
            {parent.label}
          </Link>
        ))}
        <Typography color="textPrimary">{currentLabel}</Typography>
      </Breadcrumbs>
    </>
  );
};

Breadcrumb.defaultProps = {
  parents: [],
};

Breadcrumb.propTypes = {
  parents: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  currentLabel: PropTypes.string.isRequired,
};

export default Breadcrumb;
