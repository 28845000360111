import { createContext } from 'react';

/**
 * @typedef {object} SaladbarContext
 * @property {func} enqueueNotification - Call as enqueueNotification(options)
 * where options include message and severity of 'error' ,'warning', 'info' or
 * 'success'
 * @property {func} enqueueSuccessNotification - Call as const key =
 * enqueueSuccessNotification(msg),
 * @property {func} enqueueInfoNotification - Call as const key =
 * enqueueInfoNotification(msg),
 * @property {func} enqueueWarningNotification - Call as const key =
 * enqueueWarningNotification(msg),
 * @property {func} enqueueErrorNotification - Call as const key =
 * enqueueErrorNotification(msg),
 * @property {func} removeNotification - Call as enqueueErrorNotification(key),
*/

/**
 * @ignore
 */
const stub = () => {
  throw new Error('SaladbarContext can only be used in a child of a <SaladbarProvider>');
};

// The defaultValue argument is only used when a component does not have a
// matching Provider above it in the tree. See
// https://reactjs.org/docs/context.html#reactcreatecontext
// Using createContext doesn't itself maintain any type of state exactly, it
// just provides a way for children to access shared references.
const defaultValue = {
  enqueueNotification: stub,
  enqueueSuccessNotification: stub,
  enqueueInfoNotification: stub,
  enqueueWarningNotification: stub,
  enqueueErrorNotification: stub,
  removeNotification: stub,
};

/**
 * @return  {SaladbarContext}
 */
const SaladbarContext = createContext(defaultValue);

export default SaladbarContext;
