import React, { useState } from 'react';
import {
  EditorState, ContentState, convertToRaw,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';

import greensBlockStyleFn from '../../../helpers/styling';
import ToolbarTokens from './ToolbarTokens';

// Draft.Js generates this as HTML on empty content.
const HTML_EMPTY = '<p></p>';

const externalValueToInternalEditorState = (value) => {
  if (!value) return EditorState.createEmpty();

  const blocksFromHtml = htmlToDraft(value);
  if (blocksFromHtml) {
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    return EditorState.createWithContent(contentState);
  }

  return EditorState.createEmpty();
};

const internalEditorStateToExternalValue = (editorState) => {
  const draftHTMLContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  if (draftHTMLContent.trim() === HTML_EMPTY) return '';

  return draftHTMLContent;
};

/**
 * A wrapper around draftjs Editor component, with custom toolbar.
 *
 * Main purpose of being in a separate component is to make usage (and testing)
 * simpler by abstracting away the details of EditorState and having it work
 * more like other form components. There is a value prop and a onChange prop,
 * which works nicely with formik and the like.
 *
 * Internally the component takes care of any necessary conversions.
 *
 * Note: the onChange works slightly differently in that it does not use a
 * SyntheticEvent and just calls back with the value instead. Which means you
 * have to set the prop like `onChange={(v) => formik.setFieldValue('body', v)}`
 * rather than simply onChange={formik.handleOnChange}. This is a limitation of
 * React.
 */
const RosterMailerEmailBodyEditor = ({
  value, onChange, ...rest
}) => {
  const [editorState, setEditorState] = useState(externalValueToInternalEditorState(value));

  // If he has a useEffect here with editorState as dependency, we'd get an
  // infinite loop. Instead we just have a normal function to change edit
  // changes.
  const handleEditorStateChange = (newState) => {
    setEditorState(newState);
    const newValue = internalEditorStateToExternalValue(newState);
    onChange(newValue);
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleEditorStateChange}
      blockStyleFn={greensBlockStyleFn}
      wrapperClassName="wrapper-class-draftjs"
      editorClassName="editor-class-draftjs"
      toolbarClassName="toolbar-class-draftjs"
      toolbarCustomButtons={[<ToolbarTokens />]}
      placeholder="Enter body text"
      spellCheck
      stripPastedStyles
      toolbar={{
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'history'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        blockType: {
          options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote', 'Code'],
        },
        history: { inDropdown: true },
        colorPicker: {
          colors: ['rgb(0,166,81)', 'rgb(0,140,68)', 'rgb(0,114,54)', 'rgb(245,135,31)', 'rgb(0,168,141)', 'rgb(212,60,149)', 'rgb(102,45,145)', 'rgb(0,0,0)'],
        },
        image: {
          uploadEnabled: false,
        },
      }}
      ariaLabel="Rich Text Body Editor"
      {...rest}
    />
  );
};

RosterMailerEmailBodyEditor.defaultProps = {
  onChange: () => {},
  value: '',
};

RosterMailerEmailBodyEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default RosterMailerEmailBodyEditor;
