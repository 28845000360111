import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Tooltip,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import InfoIcon from '@material-ui/icons/Info';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ListIcon from '@material-ui/icons/List';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { supportedTokens as tokenOptions, environment, microfrontendURLPrefix } from '../../config';
import { ReactComponent as Logo } from '../../assets/logo_white.svg';
import FakeErrorButton from '../FakeErrorButton/index';
import HelpDialog from '../HelpDialog';
import SendingOptionsDialog from '../SendingOptionsDialog';
import styles from './styles.module.scss';

export default withRouter(() => {
  const drawerWidth = 240;
  const [dialogTokenHelpOpen, setdialogTokenHelpOpen] = React.useState(false);
  const [dialogSendingOptionsOpen, setdialogSendingOptionsOpen] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);

  const handleInfoMenuClick = () => {
    setInfoOpen(!infoOpen);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    logo: {
      padding: 5,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { logout } = useAuth0();

  return (
    <>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={styles.root}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            title="Menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" aria-level="1" style={{ flexGrow: 1 }}>
            Roster Mailer
          </Typography>

          {['local', 'dev'].includes(environment) && (
            <Tooltip title="Click to trigger an error on purpose. Tempting isn't it?" arrow>
              <span>
                <FakeErrorButton technique="ApiError">
                  <span role="img" aria-label="bomb">
                    💣
                  </span>
                  {' '}
                  Break things
                </FakeErrorButton>
              </span>
            </Tooltip>
          )}

          <Logo title="Australian Greens" className={classes.logo} />
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button key="Home" component={Link} to={microfrontendURLPrefix || '/'}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={handleInfoMenuClick}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Info" />
            {infoOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={infoOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                key="Token Help"
                onClick={() => setdialogTokenHelpOpen(true)}
                className={classes.nested}
              >
                <ListItemIcon>
                  <RecentActorsIcon />
                </ListItemIcon>
                <ListItemText primary="Tokens Guide" />
              </ListItem>
              <ListItem
                button
                key="Sending Help"
                onClick={() => setdialogSendingOptionsOpen(true)}
                className={classes.nested}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary="Sending Options" />
              </ListItem>
            </List>
          </Collapse>

          <Divider />
          {environment !== 'prod' && (
            <ListItem
              button
              key="Get Token"
              component={Link}
              edge="end"
              to={`${microfrontendURLPrefix}/token`}
            >
              <ListItemIcon>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary="Get Token" />
            </ListItem>
          )}
          <ListItem
            button
            key="Logout"
            onClick={() => logout({ returnTo: `${window.location.origin}` })}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>

      <HelpDialog
        open={dialogTokenHelpOpen}
        onClose={() => setdialogTokenHelpOpen(false)}
        aria-labelledby="tokens-help-dialog"
        aria-describedby="tokens-help-dialog"
        title="Tokens Guide"
        summary=""
        data={tokenOptions}
      />
      <SendingOptionsDialog
        isOpen={dialogSendingOptionsOpen}
        onClose={() => setdialogSendingOptionsOpen(false)}
      />
    </>
  );
});
