import { ACTIONS } from '../actions';
import { BACKEND_STATUS } from '../constants';

// Note about statuses
// We ASSUME it is up unless we know for certain it is down, but maybe in
// future it will make sense to reset to undefined or something

const initialState = {
  status: BACKEND_STATUS.UP,
  downReason: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.BACKEND_STATUS_CHANGED:
      return {
        ...state,
        status: action.payload.status,
        downReason: action.payload.downReason ?? null,
      };
    default:
      return state;
  }
};
