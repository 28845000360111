import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userDetails from './userDetails';
import mailing from './mailing';
import elections from './elections';
import recipients from './recipients';
import deliveries from './deliveries';
import mailingtemplates from './mailingtemplates';
import backendStatus from './backendStatus';

export default (history) => combineReducers({
  router: connectRouter(history),
  userDetails,
  mailing,
  elections,
  recipients,
  deliveries,
  mailingtemplates,
  backendStatus,
});
