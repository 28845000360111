import React, { useEffect } from 'react';
import moment from 'moment';
import {
  FormLabel, Grid, Typography, Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { PropTypes } from 'prop-types';
import TextField from '../fields/TextField';
import SelectControl from '../fields/SelectControl';
import {
  getMailingTemplatesList, testTheMailing,
} from '../../actions';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EMAIL_REGEX, GREENS_EMAIL_REGEX, GREENS_PERM_SUBDOMAINS_REGEX } from '../../constants';
import RosterMailerEmailBodyEditor from '../fields/RosterMailerEmailBodyEditor';
import { useSaladbar } from '../../saladbar';

// Should match the backend
const MAX_TEST_EMAIL_ADDRESSES = 5;

// Avoiding yup for email address validation

function validateEmail(email) {
  const re = EMAIL_REGEX;
  return re.test(String(email).toLowerCase());
}

function validateGreensEmail(email) {
  const re = GREENS_EMAIL_REGEX;
  return re.test(String(email).toLowerCase());
}

function validateGreensDomainEmail(email) {
  const re = GREENS_PERM_SUBDOMAINS_REGEX;
  return re.test(String(email).toLowerCase());
}

const MailingContent = ({ formik }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const mailingTemplateList = useSelector(
    (redux) => redux.mailingtemplates?.mailingTemplateList,
  ) ?? [];
  const electionList = useSelector((redux) => redux.elections.electionList) ?? [];
  const { enqueueNotification } = useSaladbar();
  const [testEmailDialogOpen, setTestEmailDialogOpen] = React.useState(false);

  const handleTestEmailDialogOpen = () => {
    setTestEmailDialogOpen(true);
  };

  const handleTestEmailDialogClose = () => {
    setTestEmailDialogOpen(false);
  };

  const handleTestEmailDialogSend = async () => {
    const notification = {};
    try {
      const token = await getAccessTokenSilently();
      const result = await dispatch(testTheMailing(token, formik.values));
      const { numSuccessful, numFailed } = result?.data ?? {};
      notification.message = `The test mailing has been sent out. ${numSuccessful} emails succeeded and ${numFailed} failed.`;
      notification.severity = (result?.status === 200 && numFailed === 0) ? 'success' : 'warning';
      setTestEmailDialogOpen(false);
    } catch (err) {
      notification.message = 'Failed to send test email: Failed to communicate with server or request rejected';
      notification.severity = 'error';
    }
    enqueueNotification(notification);
  };

  useEffect(() => {
    const startGetMailingTemplateList = async () => {
      const token = await getAccessTokenSilently();
      dispatch(getMailingTemplatesList(token));
    };

    startGetMailingTemplateList();
  }, [dispatch, getAccessTokenSilently]);

  const formDisplayObject = (item) => ({
    value: item.id,
    label: item.name,
  });

  const nullVal = '';
  const mailingTemplateItems = mailingTemplateList?.map(formDisplayObject);
  if (mailingTemplateItems.length > 0) {
    mailingTemplateItems.unshift({ value: nullVal, label: '(No header and footer)' });
  }
  const templateLabel = !formik.values.mailingtemplateId ? nullVal : 'Header and footer';

  const mapMailingTemplateId = (v) => { if (v !== nullVal) { return v; } return null; };

  let testEmailStatus = 0;
  let testHelperText = 'Enter emails separated by commas and/or spaces';
  if (!Array.isArray(formik.values.testEmailAddresses)
  || formik.values.testEmailAddresses.length === 0) {
    testEmailStatus = 1;
  } else if (!formik.values.testEmailAddresses.every(validateEmail)) {
    testEmailStatus = 2;
    testHelperText = 'Please use only valid email addresses';
  } else if (formik.values.testEmailAddresses.length > MAX_TEST_EMAIL_ADDRESSES) {
    testEmailStatus = 3;
    testHelperText = `Please use a maximum of ${MAX_TEST_EMAIL_ADDRESSES} email addresses`;
  }

  const enableButton = (!testEmailStatus
    && formik.values.body
    && formik.values.senderName
    && formik.values.senderEmail
    && validateEmail(formik.values.senderEmail)
    && validateGreensEmail(formik.values.senderEmail)
    && validateGreensDomainEmail(formik.values.senderEmail)
    && (formik.values.replyEmail === '' || validateEmail(formik.values.replyEmail))
    && formik.values.subject);

  const electionListDateMapping = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const election of electionList) {
    electionListDateMapping[election.id] = election.electionDate;
  }
  const currentCalcElectionDate = formik.values.election in electionListDateMapping
    ? electionListDateMapping[formik.values.election]
    : '';

  const hasPrepollMailingStartedDate = formik.values.electionDate
    ? formik.values.electionDate
    : currentCalcElectionDate;

  const hasPrepollMailingStarted = formik.values.hasStartedDeliveries
  && formik.values.isDailyPrepoll && moment(hasPrepollMailingStartedDate).isSameOrBefore(moment());

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={5}>
          <Typography variant="h6">Select header and footer</Typography>
          <SelectControl
            name="mailingtemplateId"
            label={templateLabel}
            value={formik.values.mailingtemplateId ?? nullVal}
            dataItems={mailingTemplateItems}
            onChange={(e) => { formik.setFieldValue('mailingtemplateId', mapMailingTemplateId(e.target.value)); }}
            disabled={formik.values.active
              || mailingTemplateItems.length === 0
              || hasPrepollMailingStarted}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Email body</Typography>
          <RosterMailerEmailBodyEditor
            name="body"
            value={formik.values.body ?? ''}
            onChange={(v) => formik.setFieldValue('body', v)}
            readOnly={formik.values.active}
            toolbarHidden={formik.values.active}
            ariaLabel="Rich Text Body Editor"
            disabled={formik.values.active || hasPrepollMailingStarted}
          />
          <FormLabel
            error={!!formik.errors?.body}
          >
            {(formik.errors?.body) ? formik.errors.body : ''}
          </FormLabel>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Test email</Typography>
          <Typography variant="body2">Preview your mailing by sending a test to a maximum of five email addresses at a time</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="testEmailAddressesStr"
            type="text"
            label="Test email addresses"
            fullWidth
            value={formik.values.testEmailAddressesStr || ''}
            onChange={(e) => {
              formik.setFieldValue('testEmailAddressesStr', e.target.value);
              formik.setFieldValue('testEmailAddresses', e.target.value.split(/[ ,]+/).filter(Boolean));
            }}
            helperText={testHelperText}
            error={Boolean(formik.values.testEmailAddressesStr && testEmailStatus)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                if (enableButton) {
                  handleTestEmailDialogOpen();
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleTestEmailDialogOpen}
            disabled={!enableButton}
          >
            Send Test Emails
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={testEmailDialogOpen}
        onClose={handleTestEmailDialogClose}
        aria-labelledby="test-email-confirm-dialog-title"
        aria-describedby="test-email-confirm-dialog-description"
        overlaystyle={{ backgroundColor: 'transparent' }}
      >
        <DialogTitle id="test-email-confirm-dialog-title">Send a test email?</DialogTitle>
        <DialogContent>
          <DialogContentText id="test-email-confirm-dialog-description">
            Are you sure you want to send a test email?
            <br />
            <em>Note: any tokens in the mailing may be substituted with test data.</em>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTestEmailDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTestEmailDialogSend} color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MailingContent.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      subject: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      election: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      senderEmail: PropTypes.string.isRequired,
      replyEmail: PropTypes.string.isRequired,
      testEmailAddressesStr: PropTypes.string,
      testEmailAddresses: PropTypes.arrayOf(PropTypes.string),
      active: PropTypes.bool.isRequired,
      mailingtemplateId: PropTypes.number,
      hasStartedDeliveries: PropTypes.bool,
      isDailyPrepoll: PropTypes.bool,
      isPeriodic: PropTypes.bool,
      electionDate: PropTypes.string,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};

export default MailingContent;
