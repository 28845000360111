import { createTheme } from '@material-ui/core/styles';

export const fed21Theme = createTheme({
  palette: {
    primary: {
      main: '#00A651',
      light: '#A3D39C',
      dark: '#007236',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#F5871F',
      light: '#FCC589',
      dark: '#A2590A',
      contrastText: '#FFF',
    },
    warning: {
      main: '#662D91',
      light: '#BD8CBF',
      dark: '#440E62',
      contrastText: '#FFF',
    },
    error: {
      main: '#D43C95',
      light: '#8F2064',
      dark: '#F9CDE0',
      contrastText: '#FFF',
    },
    info: {
      main: '#00A88D',
      light: '#ADDCCF',
      dark: '#005243',
      contrastText: '#FFF',
    },
    success: {
      main: '#00A651',
      light: '#A3D39C',
      dark: '#007236',
      contrastText: '#FFF',
    },
  },
});
