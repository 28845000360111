import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';

const FeedbackModal = ({ message }) => (
  <Card variant="outlined">
    <CardContent>
      {message}
    </CardContent>
  </Card>
);

FeedbackModal.defaultProps = {
  message: '',
};

FeedbackModal.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default FeedbackModal;
