/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box, Button, Grid, Switch, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Skeleton from '@material-ui/lab/Skeleton';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RosterMailerTable from '../../fields/RosterMailerTable';
import { getRecipientsList, getDeliveriesList } from '../../../actions';
import {
  logger,
  noop,
  objectsEqualSpecificFields,
  momenttoHHMMTime,
  hhmmTimeToMoment,
} from '../../../helpers';
import {
  isBlank,
  validateForActivation,
  validateForGetRecipients,
  validateForSendOnlyOnce,
} from '../helpers/validation';
import { LOADING_STATUS } from '../../../constants';
import {
  EMAILS_TABLE_COLUMNS,
  mapDeliveriesRecipientsData,
  chooseEmailsTableEmptyMessage,
} from './emailsTable';
import { fed21Theme } from '../../../theme';
import SendingOptionsDialog from '../../SendingOptionsDialog';

const AUDIENCE_FIELDS = ['electorates', 'election', 'state', 'audienceTypes', 'shiftTypes'];

// If we are going to present validation information to casual users unversed in
// mixedCase, perhaps use more friendly terms like "Audience type(s)" rather than
// 'audienceTypes' in error messages. It just adds some polish to the Roster Mailer

const VALIDATION_FIELD_LABELS = {
  audienceTypes: 'Audience type(s)',
  body: 'Email body',
  election: 'Election',
  electorates: 'Electorate(s)',
  mailingtemplateId: 'Select header and footer',
  name: 'Name',
  replyEmail: 'Reply-To email',
  scheduleStart: 'Start sending on',
  scheduleStop: 'Stop sending on',
  senderEmail: 'Sender email',
  senderName: 'Sender name',
  shiftTypes: 'Shift type(s)',
  state: 'State',
  subject: 'Email subject',
  scheduleOnce: 'Send once at',
  dailyPrepollTime: 'Send daily at',
};

const START_STOP_TIME_ERRORS = {
  'Start sending on': 'Add a start date and time',
  'Stop sending on': 'Add a stop date and time',
};

function prettyPrintValidationMessages(validationMessages) {
  const validationPrettyPrinted = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const fieldName in validationMessages) {
    if (fieldName in VALIDATION_FIELD_LABELS) {
      validationPrettyPrinted[VALIDATION_FIELD_LABELS[fieldName]] = validationMessages[fieldName];
    } else {
      validationPrettyPrinted[fieldName] = validationMessages[fieldName];
    }
  }
  return validationPrettyPrinted;
}

const OrangeCheckbox = withStyles({
  root: {
    color: fed21Theme.palette.secondary.main,
    '&$checked': {
      color: fed21Theme.palette.secondary.main,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SendSwitch = withStyles({
  root: {
    width: 90,
    height: 48,
    padding: 8,
    fontFamily: 'Helvetica, Arial, serif',
  },
  switchBase: {
    padding: 11,
    color: '#ff6a00',
  },
  thumb: {
    width: 26,
    height: 26,
    backgroundColor: '#fff',
  },
  track: {
    background: fed21Theme.palette.secondary.main,
    opacity: '1 !important',
    borderRadius: 20,
    position: 'relative',
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      color: '#fff',
      textAlign: 'center',
    },
    '&:before': {
      content: '"later"',
      left: 4,
      opacity: 0,
    },
    '&:after': {
      content: '"now"',
      right: 4,
    },
  },
  checked: {
    '&$switchBase': {
      color: '#185a9d',
      transform: 'translateX(40px)',
      '&:hover': {
        backgroundColor: 'rgba(24,90,257,0.08)',
      },
    },
    '& $thumb': {
      backgroundColor: '#fff',
    },
    '& + $track': {
      background: fed21Theme.palette.secondary.main,
      '&:before': {
        opacity: 1,
      },
      '&:after': {
        opacity: 0,
      },
    },
  },
})((props) => <Switch {...props} />);

const defaultArray = [];

const MailingFinalise = ({
  formik, mailingSendOnce, setMailingSendOnce, mailingId,
}) => {
  const [readyToActivate, setReadyToActivate] = useState(false);
  const [activateValidationErrors, setActivateValidationErrors] = useState({});
  const [readyToGetRecipients, setReadyToGetRecipients] = useState(false);
  const [readyToSendOnlyOnce, setReadyToSendOnlyOnce] = useState({});
  const [readyToGetDeliveries, setReadyToGetDeliveries] = useState(false);
  const [anyAudienceFieldsAreDirty, setAnyAudienceFieldsAreDirty] = useState(true);
  const [dialogActDeactOpen, setDialogActDeactOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const recipientsList = useSelector((state) => state.recipients.recipientsList) ?? defaultArray;
  const getRecipientsStatus = useSelector((state) => state.recipients.getRecipientsStatus);
  const deliveriesList = useSelector((state) => state.deliveries.deliveriesList) ?? defaultArray;
  const getDeliveriesStatus = useSelector((state) => state.deliveries.getDeliveriesStatus);
  const electionList = useSelector((redux) => redux.elections.electionList) ?? defaultArray;
  const createMailingStatus = useSelector((state) => state?.mailing?.createMailingStatus);
  const updateMailingStatus = useSelector((state) => state?.mailing?.updateMailingStatus);
  const [sendLater, setSendLater] = React.useState(false);
  const [showSetupErrors, setShowSetupErrors] = React.useState(false);
  const [showContentErrors, setShowContentErrors] = React.useState(false);
  const [showScheduleErrors, setShowScheduleErrors] = React.useState(false);
  const [showOnceOffErrors, setShowOnceOffErrors] = React.useState(false);
  const [dateToOpen, setDateToOpen] = useState(false);
  const [dateFromOpen, setDateFromOpen] = useState(false);
  const [activateForPrepoll, setActivateForPrepoll] = useState(false);
  const [dialogSendingOptionsOpen, setdialogSendingOptionsOpen] = React.useState(false);

  const getRecipients = useCallback(async () => {
    await dispatch(getRecipientsList(await getAccessTokenSilently(), mailingId));
  }, [dispatch, mailingId, getAccessTokenSilently]);

  useEffect(() => {
    if (
      getRecipientsStatus === LOADING_STATUS.INIT
      && readyToGetRecipients
      && (!recipientsList || recipientsList.length === 0)
      && mailingId
      && mailingId !== '0'
    ) {
      getRecipients();
    }
  }, [
    readyToGetRecipients,
    recipientsList,
    getRecipientsStatus,
    dispatch,
    mailingId,
    getAccessTokenSilently,
    getRecipients,
  ]);

  useEffect(() => {
    if (
      readyToGetRecipients
      && updateMailingStatus === LOADING_STATUS.SUCCESS
      && mailingId
      && mailingId !== '0'
    ) {
      getRecipients();
    }
  }, [
    readyToGetRecipients,
    dispatch,
    mailingId,
    getAccessTokenSilently,
    updateMailingStatus,
    getRecipients,
  ]);

  useEffect(() => {
    if (
      readyToGetRecipients
      && createMailingStatus === LOADING_STATUS.SUCCESS
      && mailingId
      && mailingId !== '0'
    ) {
      getRecipients();
    }
  }, [
    readyToGetRecipients,
    dispatch,
    mailingId,
    getAccessTokenSilently,
    createMailingStatus,
    getRecipients,
  ]);

  const getDeliveries = useCallback(async () => {
    await dispatch(getDeliveriesList(await getAccessTokenSilently(), mailingId));
  }, [dispatch, mailingId, getAccessTokenSilently]);

  useEffect(() => {
    if (
      getDeliveriesStatus === LOADING_STATUS.INIT
      && readyToGetDeliveries
      && (!deliveriesList || deliveriesList.length === 0)
      && mailingId
      && mailingId !== '0'
    ) {
      getDeliveries();
    }
  }, [
    readyToGetDeliveries,
    deliveriesList,
    getDeliveriesStatus,
    dispatch,
    mailingId,
    getAccessTokenSilently,
    getDeliveries,
  ]);

  useEffect(() => {
    if (
      readyToGetDeliveries
      && updateMailingStatus === LOADING_STATUS.SUCCESS
      && mailingId
      && mailingId !== '0'
    ) {
      getDeliveries();
    }
  }, [
    readyToGetDeliveries,
    dispatch,
    mailingId,
    getAccessTokenSilently,
    updateMailingStatus,
    getDeliveries,
  ]);

  useEffect(() => {
    if (
      readyToGetDeliveries
      && createMailingStatus === LOADING_STATUS.SUCCESS
      && mailingId
      && mailingId !== '0'
    ) {
      getDeliveries();
    }
  }, [
    readyToGetDeliveries,
    dispatch,
    mailingId,
    getAccessTokenSilently,
    createMailingStatus,
    getDeliveries,
  ]);

  const emailsTableEmptyMessage = chooseEmailsTableEmptyMessage(
    getRecipientsStatus,
    recipientsList,
    readyToGetRecipients,
    anyAudienceFieldsAreDirty,
  );

  const showTableAsLoading = emailsTableEmptyMessage === ''
    && (getRecipientsStatus === LOADING_STATUS.LOADING
      || getRecipientsStatus === LOADING_STATUS.INIT
      || getDeliveriesStatus === LOADING_STATUS.LOADING
      || getDeliveriesStatus === LOADING_STATUS.INIT);

  // Only bother mapping the emails table rows if we want to display it
  const dataSet = showTableAsLoading
    ? []
    : mapDeliveriesRecipientsData(deliveriesList ?? [], recipientsList ?? []);

  const emailsSentStatus = () => {
    if (showTableAsLoading) return '';
    const sent = dataSet.filter((x) => x?.status === 'Sent').length;
    const { length } = dataSet;
    return `${sent}/${length} emails sent`;
  };

  const activateMailing = async () => {
    if (!formik.values.active) {
      // Activate
      await formik.setFieldValue('active', true);
      await formik.setFieldValue('isSendOnlyOnce', false);
      await formik.setFieldValue('isDailyPrepoll', false);
      await formik.setFieldValue('isPeriodic', true);

      const errors = await formik.validateForm();

      if (Object.keys(errors).length > 0) {
        logger.debug('Mailing not ready for activation, refusing to save');
        await formik.setFieldValue('active', false, false);
        return noop;
      }
      return formik.submitForm();
    }
    // Deactivate
    await formik.setFieldValue('active', false, false);
    return formik.submitForm();
  };

  const sendMailingOnce = async () => {
    if (!formik.values.active) {
      // Activate
      await formik.setFieldValue('active', true);
      await formik.setFieldValue('isSendOnlyOnce', true);
      await formik.setFieldValue('isDailyPrepoll', false);
      await formik.setFieldValue('isPeriodic', false);
      await formik.setFieldValue('isResendOnRosterchange', false);
      const errors = await formik.validateForm();

      if (Object.keys(errors).length > 0) {
        logger.debug('Mailing not ready for activation, refusing to save');
        await formik.setFieldValue('active', false, false);
        return noop;
      }
      setMailingSendOnce(true);
      return formik.submitForm();
    }
    // Deactivate
    await formik.setFieldValue('active', false, false);
    return formik.submitForm();
  };

  const sendDailyPrepoll = async () => {
    if (!formik.values.active) {
      // Activate
      await formik.setFieldValue('active', true);
      await formik.setFieldValue('isDailyPrepoll', true);
      await formik.setFieldValue('isSendOnlyOnce', false);
      await formik.setFieldValue('isPeriodic', false);

      const errors = await formik.validateForm();

      if (Object.keys(errors).length > 0) {
        logger.debug('Mailing not ready for activation, refusing to save');
        await formik.setFieldValue('active', false, false);
        return noop;
      }
      return formik.submitForm();
    }
    // Deactivate
    await formik.setFieldValue('active', false, false);
    return formik.submitForm();
  };

  useEffect(() => {
    // This is not done as part of the formik validation because that would
    // require setting the active field first

    const validForActivationErrors = validateForActivation(formik.values, formik.errors);
    const prettierActivationMessages = prettyPrintValidationMessages(validForActivationErrors);
    setActivateValidationErrors(prettierActivationMessages);
    setReadyToActivate(formik.isValid && Object.keys(validForActivationErrors).length === 0);

    setShowSetupErrors(
      VALIDATION_FIELD_LABELS.audienceTypes in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.election in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.electorates in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.name in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.replyEmail in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.senderEmail in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.senderName in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.shiftTypes in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.state in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.subject in prettierActivationMessages,
    );

    setShowContentErrors(
      VALIDATION_FIELD_LABELS.body in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.mailingtemplateId in prettierActivationMessages,
    );

    setShowScheduleErrors(
      VALIDATION_FIELD_LABELS.scheduleStart in prettierActivationMessages
        || VALIDATION_FIELD_LABELS.scheduleStop in prettierActivationMessages,
    );

    setShowOnceOffErrors(formik.values.scheduleStart || formik.values.scheduleStop);

    const validForSendOnlyOnce = validateForSendOnlyOnce(formik.values);
    setReadyToSendOnlyOnce(formik.isValid && Object.keys(validForSendOnlyOnce).length === 0);

    const getRecipientsValidationErrors = validateForGetRecipients(formik.values);
    setReadyToGetRecipients(
      formik.isValid && Object.keys(getRecipientsValidationErrors).length === 0,
    );

    setReadyToGetDeliveries(
      formik.isValid && Object.keys(getRecipientsValidationErrors).length === 0,
    );
  }, [formik.values, formik.isValid, formik.initialValues, formik.errors]);

  useEffect(() => {
    setAnyAudienceFieldsAreDirty(
      !objectsEqualSpecificFields(formik.values, formik.initialValues, AUDIENCE_FIELDS),
    );
  }, [formik.values, formik.initialValues]);

  const scheduleStartMinDate = formik.values.active ? null : moment();
  // eslint-disable-next-line no-nested-ternary
  const scheduleStopMinDate = formik.values.active
    ? null
    : formik.values.scheduleStart && moment().isSameOrBefore(formik.values.scheduleStart)
      ? formik.values.scheduleStart
      : moment();

  const handleChange = (event) => {
    formik.setFieldValue('isResendOnRosterchange', event.target.checked);
  };

  const handleChangeSendLater = (e) => {
    if (!e.target.checked) formik.setFieldValue('scheduleOnce', '');
    setSendLater(e.target.checked);
  };

  const useStyles = makeStyles((theme) => ({
    highlight: {
      background: theme.palette.primary.light,
    },
    firstHighlight: {
      extend: 'highlight',
      position: 'relative',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    endHighlight: {
      extend: 'highlight',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
  }));
  const classes = useStyles();

  const renderDay = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    const isBetween = moment(date).isBetween(
      moment(formik.values.scheduleStart),
      moment(formik.values.scheduleStop),
      'day',
      '[]',
    );
    const isFrom = moment(date).isSame(moment(formik.values.scheduleStart), 'day') && dayInCurrentMonth;
    const isTo = moment(date).isSame(moment(formik.values.scheduleStop), 'day');
    const wrapperClassName = clsx({
      [classes.highlight]: isBetween,
      [classes.firstHighlight]: isFrom,
      [classes.endHighlight]: isTo,
    });
    return <div className={wrapperClassName}>{dayComponent}</div>;
  };

  const onDateFromClose = () => {
    setDateFromOpen(false);
    if (formik.values.scheduleStart && !formik.values.scheduleStop) {
      setDateToOpen(true);
    }
  };

  const defaultDateProps = {
    mask: '____-__-__ __:__ _',
    format: 'YYYY-MM-DD hh:mm a',
    variant: 'inline',
    margin: 'normal',
    autoOk: true,
    disablePast: !formik.values.active,
    disabled: formik.values.active,
  };

  const defaultTimeProps = {
    mask: '__:__ _',
    format: 'hh:mm a',
    variant: 'inline',
    margin: 'normal',
    autoOk: true,
    disablePast: !formik.values.active,
    disabled: formik.values.active,
  };

  const electionListDateMapping = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const election of electionList) {
    electionListDateMapping[election.id] = election.electionDate;
  }
  const currentCalcElectionDate = formik.values.election in electionListDateMapping
    ? electionListDateMapping[formik.values.election]
    : '';

  const hasPrepollMailingStartedDate = formik.values.electionDate
    ? formik.values.electionDate
    : currentCalcElectionDate;

  const hasPrepollMailingStarted = formik.values.hasStartedDeliveries
    && formik.values.isDailyPrepoll
    && moment(hasPrepollMailingStartedDate).isSameOrBefore(moment());

  return (
    <>
      <Grid container spacing={5} alignItems="flex-start">
        <Grid item xs>
          <Grid item xs={12}>
            <Typography variant="h6">
              <label>Sending options</label>

              <IconButton
                aria-label="Sending options help information"
                onClick={() => setdialogSendingOptionsOpen(true)}
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Typography>
          </Grid>
          <Grid container spacing={2} justifyContent="space-around">
            <Grid item xs={12}>
              <Box border={1} borderRadius={3} spacing={0} padding={2} borderColor="#DDD">
                <Grid item xs={12}>
                  <Typography align="center" variant="subtitle1">
                    Periodic mailing
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  padding={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid item xs={6}>
                      <KeyboardDateTimePicker
                        id="scheduleStart"
                        // eslint-disable-next-line max-len
                        value={
                          isBlank(formik.values.scheduleStart) ? null : formik.values.scheduleStart
                        }
                        onChange={(value) => formik.setFieldValue(
                          'scheduleStart',
                          value ? moment(value).toISOString() : null,
                        )}
                        open={dateFromOpen}
                        onOpen={() => setDateFromOpen(true)}
                        onClose={onDateFromClose}
                        label="Start sending on"
                        minDate={scheduleStartMinDate}
                        minDateMessage="Cannot start sending earlier than today"
                        {...defaultDateProps}
                        error={!isBlank(formik.errors.scheduleStart)}
                        helperText={formik.errors.scheduleStart}
                        renderDay={renderDay}
                        disabled={formik.values.active || formik.values.isDailyPrepoll}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <KeyboardDateTimePicker
                        id="scheduleStop"
                        // eslint-disable-next-line max-len
                        value={
                          isBlank(formik.values.scheduleStop) ? null : formik.values.scheduleStop
                        }
                        onChange={(value) => formik.setFieldValue(
                          'scheduleStop',
                          value ? moment(value).toISOString() : null,
                        )}
                        open={dateToOpen}
                        onOpen={() => setDateToOpen(true)}
                        onClose={() => setDateToOpen(false)}
                        label="Stop sending on"
                        minDate={scheduleStopMinDate}
                        minDateMessage="Cannot stop sending before start date"
                        {...defaultDateProps}
                        error={!isBlank(formik.errors.scheduleStop)}
                        helperText={formik.errors.scheduleStop}
                        renderDay={renderDay}
                        disabled={formik.values.active || formik.values.isDailyPrepoll}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid container alignItems="center" justifyContent="space-between">
                  <FormControlLabel
                    control={(
                      <OrangeCheckbox
                        checked={formik.values.isResendOnRosterchange}
                        onChange={handleChange}
                        name="isResendOnRosterchange"
                        id="isResendOnRosterchange"
                        disabled={formik.values.active || formik.values.isDailyPrepoll}
                      />
                    )}
                    label="Resend on roster changes"
                  />
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    data-testid="actDeactScheduledMailButton"
                    disabled={
                      !readyToActivate
                      || !formik.isValid
                      || !formik.values.name
                      || mailingSendOnce
                      || formik.values.isSendOnlyOnce
                      || formik.values.isDailyPrepoll
                      || (formik.values.active
                        && !formik.values.isSendOnlyOnce
                        && moment().isSameOrAfter(formik.values.scheduleStop))
                      || (formik.values.hasStartedDeliveries && formik.values.isSendOnlyOnce)
                    }
                    onClick={() => {
                      setActivateForPrepoll(false);
                      setDialogActDeactOpen(true);
                    }}
                  >
                    {formik.values.active ? 'Deactivate the mailing' : 'Activate the mailing'}
                  </Button>
                </Grid>

                {showScheduleErrors && !formik.values.active && !formik.values.isDailyPrepoll && (
                  <>
                    <ul>
                      {Object.entries(activateValidationErrors).map(([key, value]) => (
                        <React.Fragment key={key}>
                          {['Start sending on', 'Stop sending on'].includes(key) && (
                            <li key={key} style={{ color: fed21Theme.palette.error.main }}>
                              <Typography style={{ color: fed21Theme.palette.error.main }}>
                                {value === 'Must have a value' && (
                                  <>{START_STOP_TIME_ERRORS[key]}</>
                                )}
                                {value !== 'Must have a value' && (
                                  <>
                                    {' '}
                                    {key}
                                    :
                                    {' '}
                                    {value}
                                  </>
                                )}
                              </Typography>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h6">OR</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box border={1} borderRadius={3} spacing={0} padding={2} borderColor="#DDD">
                <Grid item>
                  <Typography align="center" variant="subtitle1">
                    One-off mailing
                  </Typography>
                </Grid>
                <Grid container alignItems="flex-end" justifyContent="space-between">
                  <Grid item>
                    <SendSwitch
                      id="sendSwitch"
                      name="sendSwitch"
                      data-testid="sendSwitch"
                      checked={sendLater || !isBlank(formik.values.scheduleOnce)}
                      onChange={handleChangeSendLater}
                      disabled={
                        formik.values.active
                        || formik.values.isDailyPrepoll
                        || formik.values.isPeriodic
                      }
                    />
                  </Grid>
                  <Grid item>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDateTimePicker
                        id="scheduleOnce"
                        data-testid="scheduleOnce"
                        label={
                          sendLater || formik.values.scheduleOnce ? 'Send once at' : 'Send once now'
                        }
                        minDate={scheduleStartMinDate}
                        minDateMessage="Cannot start sending earlier than today"
                        // eslint-disable-next-line max-len
                        value={
                          isBlank(formik.values.scheduleOnce) ? null : formik.values.scheduleOnce
                        }
                        onChange={(date) => formik.setFieldValue('scheduleOnce', date ? date.toISOString() : null)}
                        {...defaultDateProps}
                        disabled={
                          formik.values.active
                          || formik.values.isDailyPrepoll
                          || formik.values.isPeriodic
                        }
                        error={!isBlank(formik.errors.scheduleOnce)}
                        helperText={formik.errors.scheduleOnce}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Button
                      id="sendMailingOnceMailButton"
                      data-testid="sendMailingOnceMailButton"
                      type="button"
                      variant="contained"
                      color="secondary"
                      disabled={
                        !readyToSendOnlyOnce // Necessary
                        || !formik.isValid // Necessary
                        || !formik.values.name // Necessary
                        || formik.values.hasStartedDeliveries // Necessary
                        || (sendLater && !formik.values.scheduleOnce) // Necessary
                        || formik.values.isDailyPrepoll // Necessary
                        || formik.values.isPeriodic
                        || (formik.values.active && isBlank(formik.values.scheduleOnce))
                      }
                      onClick={() => {
                        sendMailingOnce();
                      }}
                    >
                      {!isBlank(formik.values.scheduleOnce)
                        ? formik.values.active
                          && !formik.values.hasStartedDeliveries
                          && formik.values.isSendOnlyOnce
                          ? 'Deactivate the mailing'
                          : 'Schedule the mailing'
                        : 'Send the mailing'}
                    </Button>
                  </Grid>
                </Grid>
                {formik.values.hasStartedDeliveries && formik.values.isSendOnlyOnce && (
                  <>
                    <br />
                    <br />
                    <Typography variant="subtitle1">The mailing has been sent out.</Typography>
                  </>
                )}

                {showOnceOffErrors && !formik.values.active && !formik.values.isPeriodic && (
                  <ul>
                    {formik.values.scheduleStart && (
                      <li style={{ color: fed21Theme.palette.error.main }}>
                        <Typography style={{ color: fed21Theme.palette.error.main }}>
                          Clear the periodic start date
                        </Typography>
                      </li>
                    )}
                    {formik.values.scheduleStop && (
                      <li style={{ color: fed21Theme.palette.error.main }}>
                        <Typography style={{ color: fed21Theme.palette.error.main }}>
                          Clear the periodic stop date
                        </Typography>
                      </li>
                    )}
                  </ul>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h6">OR</Typography>
            </Grid>

            <Grid item xs={12}>
              <Box border={1} borderRadius={3} spacing={0} padding={2} borderColor="#DDD">
                <Grid item>
                  <Typography align="center" variant="subtitle1">
                    Daily pre-poll mailing
                  </Typography>
                </Grid>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardTimePicker
                        id="dailyPrepollTime"
                        data-testid="dailyPrepollTime"
                        label="Send daily at"
                        value={hhmmTimeToMoment(formik.values.dailyPrepollTime)}
                        onChange={(time) => {
                          formik.setFieldValue('dailyPrepollTime', momenttoHHMMTime(time));
                        }}
                        {...defaultTimeProps}
                        disabled={
                          formik.values.active
                          || hasPrepollMailingStarted
                          || formik.values.isPeriodic
                        }
                        error={!isBlank(formik.errors.dailyPrepollTime)}
                        helperText={formik.errors.dailyPrepollTime}
                        keyboardIcon={<AccessTimeIcon />}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      id="actDeactPrepollMailButton"
                      data-testid="actDeactPrepollMailButton"
                      disabled={
                        !formik.isValid
                        || !formik.values.name
                        || formik.values.isPeriodic
                        || (formik.values.active && !formik.values.isDailyPrepoll)
                        || !formik.values.dailyPrepollTime
                        || (formik.values.electionDate
                          && formik.values.active
                          && moment(formik.values.electionDate).isSameOrBefore(moment()))
                        || (currentCalcElectionDate
                          && !formik.values.active
                          && moment(currentCalcElectionDate).isSameOrBefore(moment()))
                        || !(
                          formik.values.shiftTypes.length === 1
                          && formik.values.shiftTypes[0] === 'prepoll'
                        )
                      }
                      onClick={() => {
                        setActivateForPrepoll(true);
                        setDialogActDeactOpen(true);
                      }}
                    >
                      {formik.values.active ? 'Deactivate the mailing' : 'Activate the mailing'}
                    </Button>
                  </Grid>
                </Grid>

                {!formik.values.active
                  && (!(
                    formik.values.shiftTypes.length === 1
                    && formik.values.shiftTypes[0] === 'prepoll'
                  )
                    || (currentCalcElectionDate
                      && moment(currentCalcElectionDate).isSameOrBefore(moment()))) && (
                      <ul>
                        {!(
                          formik.values.shiftTypes.length === 1
                        && formik.values.shiftTypes[0] === 'prepoll'
                        ) && (
                        <li style={{ color: fed21Theme.palette.error.main }}>
                          <Typography style={{ color: fed21Theme.palette.error.main }}>
                            Pre-poll shift type (only) must be selected on the Set up tab.
                          </Typography>
                        </li>
                        )}
                        {currentCalcElectionDate
                        && !hasPrepollMailingStarted
                        && moment(currentCalcElectionDate).isSameOrBefore(moment()) && (
                          <li style={{ color: fed21Theme.palette.error.main }}>
                            <Typography style={{ color: fed21Theme.palette.error.main }}>
                              The election for this mailing must be in the future.
                            </Typography>
                          </li>
                        )}
                      </ul>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              {!readyToActivate && !formik.values.isSendOnlyOnce && (
                <>
                  {(showSetupErrors || showContentErrors) && (
                    <Typography variant="h6" style={{ color: fed21Theme.palette.error.main }}>
                      Problems with your mailing
                    </Typography>
                  )}
                  {showSetupErrors && (
                    <>
                      <Typography
                        variant="subtitle1"
                        style={{ color: fed21Theme.palette.error.main }}
                      >
                        Set up tab
                      </Typography>
                      <ul>
                        {Object.entries(activateValidationErrors).map(([key, value]) => (
                          <React.Fragment key={key}>
                            {!['Email body', 'Start sending on', 'Stop sending on'].includes(
                              key,
                            ) && (
                              <li key={key} style={{ color: fed21Theme.palette.error.main }}>
                                <Typography style={{ color: fed21Theme.palette.error.main }}>
                                  {key}
                                  :
                                  {' '}
                                  {value}
                                </Typography>
                              </li>
                            )}
                          </React.Fragment>
                        ))}
                      </ul>
                    </>
                  )}
                  {showContentErrors && (
                    <>
                      <Typography
                        variant="subtitle1"
                        style={{ color: fed21Theme.palette.error.main }}
                      >
                        Compose tab
                      </Typography>
                      <ul>
                        {Object.entries(activateValidationErrors).map(([key, value]) => (
                          <React.Fragment key={key}>
                            {['Email body', 'Select header and footer'].includes(key) && (
                              <li key={key} style={{ color: fed21Theme.palette.error.main }}>
                                <Typography style={{ color: fed21Theme.palette.error.main }}>
                                  {key}
                                  :
                                  {' '}
                                  {value}
                                </Typography>
                              </li>
                            )}
                          </React.Fragment>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid container justifyContent="space-between" spacing={0}>
            <Grid item xs>
              <Typography id="recipients-table-heading" variant="h6">
                Scheduled and sent emails
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography id="emails-sent-status" align="right" data-testid="emails-sent-status">
                {showTableAsLoading ? (
                  <Skeleton width="14ch" className="skeleton-pull-right" />
                ) : (
                  emailsSentStatus()
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RosterMailerTable
              containerTestId="emails-table-container"
              columns={EMAILS_TABLE_COLUMNS}
              data={anyAudienceFieldsAreDirty || showTableAsLoading ? [] : dataSet}
              emptyTableMessage={emailsTableEmptyMessage}
              isLoading={showTableAsLoading}
              options={{ pageSize: 10 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={dialogActDeactOpen}
        onClose={() => setDialogActDeactOpen(false)}
        aria-labelledby="activate-deactivate-dialog-title"
        aria-describedby="activate-deactivate-dialog-description"
        overlaystyle={{ backgroundColor: 'transparent' }}
      >
        <DialogTitle id="activate-deactivate-dialog-title">
          {formik.values.active ? 'Deactivate' : 'Activate'}
          {' '}
          this mailing?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="activate-deactivate-dialog-description">
            Are you sure you wish to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogActDeactOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDialogActDeactOpen(false);
              if (activateForPrepoll) {
                sendDailyPrepoll();
              } else {
                activateMailing();
              }
            }}
            color="primary"
            autoFocus
          >
            {formik.values.active ? 'Deactivate' : 'Activate'}
          </Button>
        </DialogActions>
      </Dialog>
      <SendingOptionsDialog
        isOpen={dialogSendingOptionsOpen}
        onClose={() => setdialogSendingOptionsOpen(false)}
      />
    </>
  );
};

// MAIL-317

MailingFinalise.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      election: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      audienceTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      electorates: PropTypes.arrayOf(PropTypes.string).isRequired,
      active: PropTypes.bool.isRequired,
      hasStartedDeliveries: PropTypes.bool,
      isSendOnlyOnce: PropTypes.bool,
      isResendOnRosterchange: PropTypes.bool,
      scheduleStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
      scheduleStop: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
      scheduleOnce: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
      isDailyPrepoll: PropTypes.bool,
      dailyPrepollTime: PropTypes.string,
      isPeriodic: PropTypes.bool,
      electionDate: PropTypes.string,
    }).isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      election: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      audienceTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      electorates: PropTypes.arrayOf(PropTypes.string).isRequired,
      active: PropTypes.bool.isRequired,
      hasStartedDeliveries: PropTypes.bool,
      isSendOnlyOnce: PropTypes.bool,
      isResendOnRosterchange: PropTypes.bool,
      scheduleStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
      scheduleStop: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
      scheduleOnce: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
      isDailyPrepoll: PropTypes.bool,
      dailyPrepollTime: PropTypes.string,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  mailingSendOnce: PropTypes.bool.isRequired,
  setMailingSendOnce: PropTypes.func.isRequired,
  mailingId: PropTypes.string.isRequired,
};

export default MailingFinalise;
