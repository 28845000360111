import { useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { isBlank } from '../components/MailingForm/helpers/validation';

export { default as logger } from './logger';

// For experimenting purposes
export function delay(millis) {
  return new Promise((resolve) => setTimeout(resolve, millis));
}

export function noop() { }

// For experimenting purposes
export function objectHasAll(obj, keys) {
  return keys.every((key) => key in obj);
}

/**
 * Parses val as integer, keeping in range [0, maxVal]
 *
 * @param   {any}  val        Value to parse. Must be >= 0
 * @param   {number}  maxVal  Cap for value. Must be > 0
 * @param   {number} radix    Radix
 *
 * @return  {number}  Same result as parseInt(), but will be in the range [0,
 * maxVal]
 */
export function parseIntCapped(val, maxVal = null, radix = 10) {
  let parsed = parseInt(val, radix);
  if (parsed < 0) throw new Error('val must be positive');
  if (maxVal !== null && maxVal !== undefined) {
    if (!Number.isInteger(maxVal)) throw new Error('maxVal must be integer');
    if (maxVal < 1) throw new Error('maxVal must be > 0');
    parsed = Math.min(parsed, maxVal);
  }
  return parsed;
}

/**
 * Returns the previous value of stateful value, and a function to update it.
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @see https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
 */
export function usePrevious(value, initialValue = undefined) {
  const ref = useRef(initialValue);

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const TIMESTAMP_FORMATTER_NON_TIMEZONE_OPTIONS = {
  datetime: {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  },
};

// Most of the time the default is used, so just create it once
const defaultTimestampDatetimeFormatter = new Intl.DateTimeFormat('en-AU', {
  ...TIMESTAMP_FORMATTER_NON_TIMEZONE_OPTIONS.datetime,
  // The timeZone option actually does the timezone conversion
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // client timezone
});

/**
 * Format a timestamp from a Date object) into a standard format for used across
 * interface. Seconds are never included in the time and are truncated, not
 * rounded. The time is adjusted into the timezone defined by options.timezone.
 *
 * If the timestamp provided as a string without a timezone, it is assumed to be
 * UTC.
 *
 * @param   {Date|string}  timestamp
 * A date object or string containing timestamp
 *
 * @param {string} flavour
 * Default 'datetime' (only one supported), in future may add 'time' and 'date'.
 *
 * @param   {string} timeZone
 * Default 'UTC'. Valid name from TZ database
 *
 * @return  {string}
 * E.g 22/04/21 1:31pm
 */
export function formatTimestamp(timestamp, flavour = 'datetime', timeZone = null) {
  if (flavour !== 'datetime') throw new Error('Only datetime flavour is currently implemented');

  let dateObj;

  if (typeof timestamp === 'string') {
    dateObj = new Date(timestamp);
  } else if (timestamp instanceof Date) {
    dateObj = timestamp;
  } else {
    throw new Error('timestamp must be Date or string');
  }

  const formatter = timeZone
    ? new Intl.DateTimeFormat('en-AU', { ...TIMESTAMP_FORMATTER_NON_TIMEZONE_OPTIONS.datetime, timeZone })
    : defaultTimestampDatetimeFormatter;

  let str = formatter.format(dateObj);

  // That makes something like "22/04/21, 01:31 pm" but we don't want comma and
  // want to remove the space before am/pm
  str = str.replace(/,/, '').replace(/ (am|pm)/, '$1');

  return str;
}

/**
 * Create a subset of obj containing only properties in the keepProps array.
 *
 * NOTE: Does not support nested properties, only works on root level.
 *
 * NOTE: Just copied from backend
 *
 * @param   {object}  obj        The obj object to be filtered
 * @param   {array}  keepProps   List of names of properties to keep
 *
 * @return  {object}             obj containing only properties in keepProps
 */
export function filterObjectProps(obj, keepProps) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([prop]) => keepProps.includes(prop)),
  );
}

/**
 * Same as lodash isEqual, but only compares by the specified fields.
 *
 * I couldn't think of a good name for it.
 *
 * @param   {object}  obj1
 * @param   {object}  obj2
 * @param   {array}  fields The fields/keys to restrict comparison to
 *
 * @return  {boolean}
 */
export function objectsEqualSpecificFields(obj1, obj2, fields) {
  // Probably not the most efficient way of doing this, but gets the job done
  // for now. It would probably make more sense to just to check they both have
  // all the fields and then iterate over said fields
  const obj1OnlyKeys = filterObjectProps(obj1, fields);
  const obj2OnlyKeys = filterObjectProps(obj2, fields);

  return isEqual(obj1OnlyKeys, obj2OnlyKeys);
}

/* Converts a moment value to a 24-hr "hh:mm" time in ISO time-zone, or null. */

export function momenttoHHMMTime(momentvalue) {
  if (momentvalue) {
    const timeIsoString = momentvalue.toISOString();
    const timeWithoutDateIsoString = timeIsoString
      ? timeIsoString.slice(11, 16)
      : null;
    return timeWithoutDateIsoString;
  }
  return null;
}

/*
// Converts a 24-hr "hh:mm" time in ISO time-zone back to a moment, or null.
//
// Note 1: this is only for display in a KeyboardTimePicker control, so the actual
// date is not important; just the time.
//
// Note 2: the data from PostgreSQL may be in "hh:mm:ss" format, so we need to
// discard the seconds part.
*/

export function hhmmTimeToMoment(hhmmvalue) {
  return isBlank(hhmmvalue)
    ? null
    : moment(`${moment().format('YYYY-MM-DD')}T${hhmmvalue.slice(0, 5)}:00.000Z`, moment.TIME);
}
