// The name of each error is set to the class name. Concept borrowed from gVIRS.
// Any classes extending this have the same behaviour which can be useful, and
// we can also easily identify when an Error was thrown that is a descendent of
// this using instanceof
export default class RosterMailerError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;

    // Maintains proper stack trace for where our error was thrown (only
    // available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
