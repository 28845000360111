import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import styles from './styles.module.scss';
import BackendError from '../../errors/BackendError';
import BackendServerUncontactableError from '../../errors/BackendServerUncontactableError';
import BackendDatabaseUncontactableError from '../../errors/BackendDatabaseUncontactableError';
import BackendCiviCrmUncontactableError from '../../errors/BackendCiviCrmUncontactableError';
import { setBackendStatusUp } from '../../actions';
import { getStore } from '../../store';

// eslint-disable-next-line no-unused-vars
const ErrorFallback = ({ error, componentStack, resetError }) => {
  const handleOnClick = () => {
    // We could just reload the homepage, but we can also use resetError
    // to just re-mount the unmounted children. It doesn't exactly go back to
    // exactly how things were, but it might give a better user experience.
    // window.location.href = '/';

    // This feels like a weird way of doing it. There is no redux context
    // because the error boundary is outside of it, but we still need to reset
    // the backend status otherwise will just come back here again because the
    // App loses its internal state, so the useEffect doesn't work as you'd
    // expect. If the reason for the error isn't that, doesn't matter.
    getStore().dispatch(setBackendStatusUp());
    resetError();
  };

  // The error class can be used to determine the underlying cause.
  // We could also use the serverStatus.downReason in redux store to determine
  // which is what was originally used to determine the classes.
  let details;

  // !NOTE: The messages/format is just a placeholder. I'm sure we can do better
  if (error instanceof BackendError) {
    let message = 'Unknown server issue';
    if (error instanceof BackendServerUncontactableError) {
      message = 'Unable to contact the backend server';
    } else if (error instanceof BackendDatabaseUncontactableError) {
      message = 'The backend server is running, but is unable to contact the database it needs to work.';
    } else if (error instanceof BackendCiviCrmUncontactableError) {
      message = 'The backend server is running, but is unable to contact CiviCRM';
    }
    details = (<code>{message}</code>);
  }

  return (
    <Box className={styles.centredOnScreen}>
      <Grid
        container
        spacing={0}
        align="center"
        justifyContent="center"
        height="100%"
      >
        <Grid item xs={10} sm={8} md={6}>
          <Alert
            align="left"
            severity="error"
            variant="outlined"
          >
            <AlertTitle>An error has occurred in the application</AlertTitle>
            Please report this on Ryver helpdesk and it will be fixed ASAP.
            <br />
            <br />
            Error details:
            <br />
            {details ?? <code>Unknown issue</code> }
          </Alert>
          <br />
          <br />
          <Button variant="contained" type="button" onClick={handleOnClick} color="secondary">
            Click here try again
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

ErrorFallback.defaultProps = {
  componentStack: undefined,
};

ErrorFallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  resetError: PropTypes.func.isRequired,
  componentStack: PropTypes.node,
};

export default ErrorFallback;
