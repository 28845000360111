import { AUDIENCE_TYPES, SHIFTS } from '../../../constants';
import deriveMailingStatus from '../status';

export default function mapMailingsListToTableData(mailings) {
  return mailings.map(tableDataMapper);
}

function tableDataMapper(mailing) {
  const electorateNamesSorted = findElectorateNamesAndSort(mailing.electorates ?? []);
  const audienceTypesSorted = findAudienceTypesAndSort(mailing.audienceTypes ?? []);
  const shiftTypesSorted = findShiftTypesAndSort(mailing.shiftTypes ?? []);

  return {
    ...mailing,
    electorateNamesSorted,
    audienceTypesSorted,
    shiftTypesSorted,
    electorateNamesSortedCsv: electorateNamesSorted.join(', '),
    audienceTypesSortedCsv: audienceTypesSorted.join(', '),
    shiftTypesSortedCsv: shiftTypesSorted.join(', '),
    derivedMailingStatus: deriveMailingStatus(mailing),
  };
}

function findElectorateNamesAndSort(electorates) {
  return electorates.map((x) => x.name).sort((a, b) => a.localeCompare(b));
}

function findAudienceTypesAndSort(audienceTypes) {
  const mapped = audienceTypes.map((id) => {
    const validAudienceType = AUDIENCE_TYPES.find((type) => type.id === id);
    if (!validAudienceType) return 'undefined';
    return validAudienceType.name;
  });
  return mapped.sort((a, b) => a.localeCompare(b));
}

function findShiftTypesAndSort(shiftTypes) {
  const mapped = shiftTypes.map((id) => {
    const validShiftType = SHIFTS.find((type) => type.id === id);
    if (!validShiftType) return 'undefined';
    return validShiftType.name;
  });
  return mapped.sort((a, b) => a.localeCompare(b));
}
