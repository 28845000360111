import { PropTypes } from 'prop-types';
import { integer } from 'airbnb-prop-types';

import React from 'react';

const TruncatedCsv = ({ items, maxItems }) => (
  <>
    {items.slice(0, maxItems).join(', ')}
    { items.length > maxItems
          && (
            <span>
              {' '}
              ...&nbsp;+
              {items.length - maxItems}
              &nbsp;more
            </span>
          )}
  </>
);

TruncatedCsv.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxItems: integer().isRequired,
};

export default TruncatedCsv;
