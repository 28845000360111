import { ACTIONS } from '../actions';
import { LOADING_STATUS } from '../constants';

const initialState = {
  mailingTemplateList: undefined,
  getMailingTemplateStatus: LOADING_STATUS.INIT,
  getMailingTemplateFailReason: undefined,
};

export default (state = initialState, action) => {
  if (action.type === ACTIONS.GET_MAILINGTEMPLATES_START) {
    return { ...state, getMailingTemplateStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.GET_MAILINGTEMPLATES_SUCCESS) {
    return {
      ...state,
      getMailingTemplateStatus: LOADING_STATUS.SUCCESS,
      mailingTemplateList: action.payload,
      getMailingTemplateFailReason: undefined,
    };
  }
  if (action.type === ACTIONS.GET_MAILINGTEMPLATES_FAIL) {
    return {
      ...state,
      getMailingTemplateStatus: LOADING_STATUS.FAIL,
      mailingTemplateList: undefined,
      getMailingTemplateFailReason: action.payload,
    };
  }

  if (action.type === ACTIONS.RESET_MAILINGTEMPLATES) {
    return {
      ...state,
      getMailingTemplateStatus: LOADING_STATUS.INIT,
      mailingTemplateList: undefined,
      getMailingTemplateFailReason: undefined,
    };
  }

  return state;
};
