import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const HelpDialog = ({ data, ...props }) => {
  const renderRow = (row, isHead = false) => {
    const renderDescription = (description) => (
      <Typography component="div" variant={isHead ? 'h6' : 'body1'} gutterBottom>
        {description}
      </Typography>
    );
    return (
      <Grid container key={row.key} spacing={2}>
        <Grid item xs={2}>
          <Typography component="div" variant={isHead ? 'h6' : 'body1'}>
            <p>{row.text}</p>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography component="div" variant={isHead ? 'h6' : 'body1'}>
            <p>{row.value}</p>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          { isHead && (
          <Typography component="div" variant="h6">
            <p>{row.description}</p>
          </Typography>
          )}
          { !isHead && renderDescription(row.description) }
        </Grid>
      </Grid>
    );
  };

  const renderTable = () => (
    <Grid container>
      <Grid item xs={12}>
        {renderRow({
          key: 'Headers', text: 'Name', value: 'Usage', description: 'Description',
        }, true)}
        {data.map((row) => renderRow(row))}
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog
        PaperProps={{ style: { userSelect: 'text' } }}
        {...props}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent style={{ userSelect: 'text' }}>
          { props.summary && (
          <DialogContentText>
            {props.summary}
          </DialogContentText>
          )}
          {props.children ? props.children : renderTable()}

        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

HelpDialog.defaultProps = {
  ...Dialog.defaultProps,
  fullWidth: true,
  maxWidth: 'lg',
  overlaystyle: { backgroundColor: 'transparent' },
};

const shapeType = {
  key: PropTypes.string,
  value: PropTypes.string,
  text: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

HelpDialog.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape(shapeType)),
  ...Dialog.propTypes,
};

export default HelpDialog;
