import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Spinner from '../Spinner';

// @See https://auth0.com/blog/complete-guide-to-react-user-authentication/#Protecting-Routes
// Note: Since this is a client-side guard, it is to improve the user experience
//       Its not about security, thats up to the server.
//       That's why we are calling it AuthRequiredRoute and not ProtectedRoute
function AuthRequiredRoute({ component, ...passThroughProps }) {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <Spinner />,
      })}
      // This is one of the few situations were prop spreading in JSX actually
      // makes sense, so we disable the lint checking for it
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...passThroughProps}
    />
  );
}

AuthRequiredRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthRequiredRoute;
