import { ACTIONS } from '../actions';
import { LOADING_STATUS } from '../constants';

const initialState = {
  recipientsList: undefined,
  getRecipientsStatus: LOADING_STATUS.INIT,
};

export default (state = initialState, action) => {
  if (action.type === ACTIONS.GET_RECIPIENTS_START) {
    return { ...state, getRecipientsStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.GET_RECIPIENTS_SUCCESS) {
    return {
      ...state,
      getRecipientsStatus: LOADING_STATUS.SUCCESS,
      recipientsList: action.payload,
    };
  }
  if (action.type === ACTIONS.GET_RECIPIENTS_FAIL) {
    return { ...state, getRecipientsStatus: LOADING_STATUS.FAIL };
  }
  if (action.type === ACTIONS.RESET_RECIPIENTS) {
    return {
      ...state,
      recipientsList: initialState.recipientsList,
      getRecipientsStatus: initialState.getRecipientsStatus,
    };
  }
  return state;
};
