import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const SendingOptionsDialog = ({ isOpen, onClose }) => (
  <Dialog
    open={isOpen}
    PaperProps={{ style: { userSelect: 'text' } }}
    aria-labelledby="sending-options-help-dialog"
    aria-describedby="sending-options-help-dialog"
    maxWidth="lg"
    fullWidth
    overlaystyle={{ backgroundColor: 'transparent' }}
    onClose={onClose}
  >
    <DialogTitle>Sending Options</DialogTitle>
    <DialogContent style={{ userSelect: 'text', color: 'rgba(0,0,0,0.87)', fontFamily: '' }}>
      <Typography component="div">
        <h3>Periodic mailing</h3>
        <p>
          A periodic mailing will send an email whenever a new volunteer has a shift added to
          the roster that meets the criteria you have included for your mailing.
        </p>

        <p>
          If the ‘Resend on roster changes’ box is ticked, a new email will also be sent each
          time a volunteer has a shift changed or added.
        </p>

        <p>
          A periodic mailing will only send out emails during the date and time range that you
          specify. Any volunteers that already have applicable shifts when an activated mailing
          reaches the start time will be sent an email right away. From then on the mailing will
          send out emails as shifts are added. Any volunteer that has shifts added after the
          time range ends will not receive additional emails.
        </p>

        <h3>One-off mailing</h3>
        <p>
          A one-off mailing will send one email only at the scheduled time. An email will be
          sent to every volunteer that has one or more applicable shifts at the date and time
          the email has been scheduled to send.
        </p>

        <p>
          If you use the ‘Send once now’ option, this means that an email will be sent to every
          volunteer that has one or more applicable shifts at the time you click send.
        </p>

        <h3>Daily pre-poll mailing</h3>
        <p>
          A daily pre-poll mailing sends reminder emails to pre-poll volunteers. Any volunteers
          that have one or more pre-poll shifts on a given day will receive an email at a
          selected time on the day before. Volunteers that have multiple days of pre-poll shifts
          will receive multiple emails - one email for each day of pre-poll, and always the day
          before.
          {' '}
        </p>

        <p>
          All tokens can be used with this type of mailing, but will only populate information
          that relates to the shift/s for a given day - that is, the day after receiving the
          email.
        </p>

        <p>
          Each daily pre-poll mailing will only send out an email once per day, and only to
          email volunteers who at the time the mailing is scheduled to be sent have one or more
          applicable shifts for the next day.
        </p>

        <p>
          Any changes to a person’s roster after an email goes out do not trigger an additional
          daily pre-poll mailing. Users who wish this to happen should create a periodic mailing
          where the ‘Resend on roster changes’ box is ticked instead.
        </p>

        <p>
          Users can deactivate and reactivate daily pre-poll mailings like they can do for other
          types of mailings. However, if the selected sending time passes while the mailing is
          inactive, then reactivated later, pre-poll emails will not be sent that day.
        </p>

        <p>
          Fortunately, if this scenario happens, there is nothing preventing users from
          deactivating a pre-poll mailing, setting the sending time to later in the day, and
          then reactivating again before this time. That way, pre-poll emails will go out for
          that day.
        </p>

        <p>Daily pre-poll mailings cannot be used for reminders for election day rosters.</p>

        <h4>Sending times</h4>
        <p>
          Some of the sending options include selecting a date and/or time. The date and time
          will be set based on your local time.
        </p>

        <p>
          If you are setting up a mailing on behalf of a team in another timezone, you will need
          to take into account the time difference between yourself and that team. For example,
          if you are setting daily pre polls to go out at 6pm in Perth, and your computer is in
          Brisbane, you would set the time at 8pm.
        </p>
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

SendingOptionsDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

SendingOptionsDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,

};

export default SendingOptionsDialog;
