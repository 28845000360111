import { ACTIONS } from '../actions';
import { LOADING_STATUS } from '../constants';

const initialState = {
  deliveriesList: undefined,
  getDeliveriesStatus: LOADING_STATUS.INIT,
};

export default (state = initialState, action) => {
  if (action.type === ACTIONS.GET_DELIVERIES_START) {
    return { ...state, getDeliveriesStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.GET_DELIVERIES_SUCCESS) {
    return {
      ...state,
      getDeliveriesStatus: LOADING_STATUS.SUCCESS,
      deliveriesList: action.payload,
    };
  }
  if (action.type === ACTIONS.GET_DELIVERIES_FAIL) {
    return { ...state, getDeliveriesStatus: LOADING_STATUS.FAIL };
  }
  if (action.type === ACTIONS.RESET_DELIVERIES) {
    return {
      ...state,
      deliveriesList: initialState.deliveriesList,
      getDeliveriesStatus: initialState.getDeliveriesStatus,
    };
  }
  return state;
};
