import { ACTIONS } from '../actions';
import { LOADING_STATUS } from '../constants';

const initialState = {
  electionList: undefined,
  getElectionsStatus: LOADING_STATUS.INIT,
};

export default (state = initialState, action) => {
  if (action.type === ACTIONS.GET_ELECTIONS_START) {
    return { ...state, getElectionsStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.GET_ELECTIONS_SUCCESS) {
    return {
      ...state,
      getElectionsStatus: LOADING_STATUS.SUCCESS,
      electionList: action.payload,
    };
  }
  if (action.type === ACTIONS.GET_ELECTIONS_FAIL) {
    return { ...state, getElectionsStatus: LOADING_STATUS.FAIL };
  }
  return state;
};
