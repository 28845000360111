// ALL_CAPS versus TitleCase debate resolved - ALL_CAPS if you read closed.
// Like https://stackoverflow.com/questions/13233137/javascript-enum-pattern-naming-convention
// Also useful to have the value be a string to improve debugging, but that case
// be debatable. E.g.
// export const LoadingStatus = {
//   INIT: 'INIT',
//   LOADING: 'LOADING',
//   SUCCESS: 'SUCCESS',
//   FAIL: 'FAIL',
// };

/**
 * Enum for loading status
 *
 * @readonly
 * @enum {number}
 */
export const LOADING_STATUS = {
  INIT: 0,
  LOADING: 1,
  SUCCESS: 2,
  FAIL: 3,
};

export const FAIL_REASON = {
  UNKNOWN: 0,
  NOT_READY_TO_ACTIVATE: 1,
};

export const BACKEND_STATUS = {
  UP: 'UP',
  DOWN: 'DOWN',
};

export const BACKEND_DOWN_REASON = {
  UNKNOWN: 'UNKNOWN',
  SERVER_UNCONTACTABLE: 'SERVER_UNCONTACTABLE',
  CIVI_CRM_UNCONTACTABLE: 'CIVI_CRM_UNCONTACTABLE',
  DATABASE_UNCONTACTABLE: 'DATABASE_UNCONTACTABLE',
};

export const STATES = [
  'ACT',
  'NSW',
  'NT',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
];

export const SHIFTS = [
  { id: 'election', name: 'Election Day' },
  { id: 'prepoll', name: 'Pre-poll' },
];

export const AUDIENCE_TYPES = [
  { id: 'vols', name: 'Booth volunteers' },
  { id: 'captains', name: 'Booth captains' },
  { id: 'setup', name: 'Booth set up' },
  { id: 'packup', name: 'Booth pack up' },
  { id: 'scrappers', name: 'Booth scrappers' },
  { id: 'scrutineers', name: 'Scrutineers' },
];

export const API_ERROR_MESSAGE_REGEX_NOT_READY_TO_ACTIVATE = /active = true, but .* is empty/;

// Email validation regex from Stack Overflow
// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Extra validation for Greens only email addresses.

export const GREENS_EMAIL_REGEX = /@([a-z]+\.)*greens\.org\.au$/;

// Described in more detail here:
// https://bitbucket.org/australian_greens/contact.greens.org.au/pull-requests/744/add-bizjmaconsultingode-extension-to
// https://github.com/australiangreens/biz.jmaconsulting.ode/blob/3af2ba7184c58da44b31f7f89c720fc3b26fec72/ode.php#L283

export const GREENS_PERM_SUBDOMAINS_REGEX = /@((nsw|vic|qld|sa|wa|tas|nt|act)\.)?greens\.org\.au$/;

export const DEFAULT_MAILING_LIST_MAX_ELECTORATES_IN_CELL = 3;
export const DEFAULT_MAILING_LIST_MAX_AUDIENCE_TYPES_IN_CELL = 1;

export const SENDING_NOTIFICATION_PROGRESS_INDICATOR = 'linear'; // Can be undefined, 'circular' or 'linear'
