/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';
import HelpDialog from '../../HelpDialog';
import '../../../css/style.css';
import { supportedTokens as tokenOptions } from '../../../config';

const ToolbarTokens = ({ onChange, editorState }) => {
  const [open, setOpen] = useState(false);
  const [dialogTokenHelpOpen, setdialogTokenHelpOpen] = React.useState(false);

  const addToken = (token) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      token,
      editorState.getCurrentInlineStyle(),
    );
    const result = EditorState.push(editorState, contentState, 'insert-characters');
    if (onChange) {
      // console.log(result.getCurrentContent().getBlockMap().toString());
      onChange(result);
    }
  };

  return (
    <>
      <div onClick={() => setOpen(!open)} className="rdw-block-wrapper" aria-label="Tokens dropdown menu" role="button" tabIndex={0}>
        <div className="rdw-dropdown-wrapper rdw-block-dropdown" style={{ width: 180 }}>
          <div className="rdw-dropdown-selectedtext">
            <span>Tokens</span>
            <div className={`rdw-dropdown-caretto${open ? 'close' : 'open'}`} />
          </div>
          <ul className={`rdw-dropdown-optionwrapper ${open ? '' : 'toolbar-class-tokens-ul'}`} aria-label="Tokens dropdown menu choices">
            {tokenOptions.map((item) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                onClick={() => addToken(item.value)}
                key={item.value}
                className="rdw-dropdownoption-default toolbar-class-tokens-li"
              >
                {item.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <IconButton
        aria-label="Tokens help information"
        onClick={() => setdialogTokenHelpOpen(true)}
      >
        <HelpOutlineIcon fontSize="small" />
      </IconButton>
      <HelpDialog
        open={dialogTokenHelpOpen}
        onClose={() => setdialogTokenHelpOpen(false)}
        aria-labelledby="tokens-help-dialog"
        aria-describedby="tokens-help-dialog"
        title="Tokens Information"
        blurb="Here is some exciting placeholder text about tokens."
        data={tokenOptions}
      />
    </>
  );
};

ToolbarTokens.defaultProps = {
  onChange: Function.prototype,
  editorState: undefined,
};
ToolbarTokens.propTypes = {
  onChange: PropTypes.func,
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func,
    getSelection: PropTypes.func,
    getCurrentInlineStyle: PropTypes.func,
  }),
};

export default ToolbarTokens;
