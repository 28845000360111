import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import styles from './styles.module.scss';

const ErrorPopin = (props) => (
  <Box className={styles.centredOnScreen}>
    <Grid
      container
      spacing={0}
      align="center"
      justifyContent="center"
      height="100%"
    >
      <Grid item xs={10} sm={8} md={6}>
        <Alert
          align="left"
          severity="error"
          variant="outlined"
        >
          <AlertTitle>{props.title}</AlertTitle>
          {props.message}
        </Alert>
        <br />
        <br />
        <Button variant="contained" type="button" onClick={props.handler} color="secondary">
          {props.button}
        </Button>
      </Grid>
    </Grid>
  </Box>
);

ErrorPopin.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  handler: PropTypes.func.isRequired,
  button: PropTypes.node.isRequired,
};

export default ErrorPopin;
