import { LOADING_STATUS } from '../../../constants/index';
import {
  formatTimestamp,
} from '../../../helpers';

export const EMAILS_TABLE_COLUMNS = [
  {
    title: 'Name', field: 'name', align: 'left', filterPlaceholder: 'Filter by...',
  },
  {
    title: 'Email', field: 'email', align: 'left', filterPlaceholder: 'Filter by...',
  },
  {
    title: 'Status', field: 'status', align: 'left', filterPlaceholder: 'Filter by...',
  },
  {
    title: 'Sent time',
    field: 'niceTimeStamp',
    align: 'right',
    filterPlaceholder: 'Filter by...',
    defaultSort: 'desc', // Latest to be sent first.
    customSort: (rowA, rowB) => {
      // if (rowA.name === NO_LONGER_ROSTERED) return -1;
      // if (rowB.name === NO_LONGER_ROSTERED) return 1;
      // null/undefined/empty first which implicitly puts the unsent emails at
      // the top as per story requirements
      if (!rowA.sentAt && !rowB.sentAt) return 0;
      if (!rowA.sentAt) return 1;
      if (!rowB.sentAt) return -1;
      // Already in ISO 8601 format, so can be compared alphanumerically
      return rowA.sentAt.localeCompare(rowB.sentAt);
    },
  },
];

const DELIVERY_STATUS_MAPPINGS = {
  unsent: 'Unsent',
  queued: 'Queued',
  sent: 'Sent',
  error: 'Error',
  delivered: 'Delivered',
  bounced: 'Bounced',
  complained: 'Complained',
  wontsend: 'Won\'t send',
};

const prettyDeliveryStatus = (status) => {
  if (!status) return 'Unsent';
  if (status in DELIVERY_STATUS_MAPPINGS) return DELIVERY_STATUS_MAPPINGS[status];
  throw new Error(`Invalid status '${status}'`);
};

const combineNames = (nickName, firstName, lastName) => {
  const nickNameTrimmed = (nickName ?? '').trim();
  const firstNameTrimmed = (firstName ?? '').trim();
  const lastNameTrimmed = (lastName ?? '').trim();

  if (nickNameTrimmed && lastNameTrimmed) return `${nickNameTrimmed} ${lastNameTrimmed}`;
  if (nickNameTrimmed) return nickNameTrimmed;
  if (firstNameTrimmed && lastNameTrimmed) return `${firstNameTrimmed} ${lastNameTrimmed}`;
  if (firstNameTrimmed) return firstNameTrimmed;
  if (lastNameTrimmed) return lastNameTrimmed;
  return '?';
};

const generateDataRow = (delivery, recipient) => ({
  id: Number(recipient?.contactId ?? delivery?.contactId),
  name: recipient
    ? combineNames(recipient?.nickName, recipient?.firstName, recipient?.lastName)
    : combineNames(
      delivery?.recipientSnapshot?.nickName,
      delivery?.recipientSnapshot?.firstName,
      delivery?.recipientSnapshot?.lastName,
    ),
  email: recipient?.email ?? delivery?.toEmailAddress,
  status: prettyDeliveryStatus(delivery?.deliveryStatus),
  sentAt: delivery?.sentAt ?? null,
  niceTimeStamp: delivery?.sentAt ? formatTimestamp(delivery.sentAt) : null,
});

/**
 * Converts array of deliveries and recipients into a form suitable to use in
 * the recipients table defined by EMAILS_TABLE_COLUMNS
 *
 * @param   {object[]}  deliveries  The deliveries
 * @param   {object[]}  recipient   The recipients
 *
 * @return  {object[]}             Deliveries and Recipients mapped to rows
 */
export function mapDeliveriesRecipientsData(deliveries, recipients) {
  const deliveryData = deliveries.map((x) => {
    const y = recipients.find((z) => Number(z.contactId) === Number(x.contactId));
    return generateDataRow(x, y);
  });
  const recipientData = recipients
    .filter((x) => !deliveries.find((y) => Number(y.contactId) === Number(x.contactId)))
    .map((y) => generateDataRow({}, y));
  return [].concat(deliveryData, recipientData);
}

/**
 * Pick a suitable message to display on the recipients table when it is empty.
 *
 * @param   {LOADING_STATUS}  getRecipientsStatus
 * @param   {array}  recipientsList
 * @param   {boolean} readyToGetRecipients
 * @param   {Formik}  formik
 * @param   {boolean}  anyAudienceFieldsAreDirty
 *
 * @return  {string}
 */
export function chooseEmailsTableEmptyMessage(
  getRecipientsStatus,
  recipientsList,
  readyToGetRecipients,
  anyAudienceFieldsAreDirty,
) {
  if (anyAudienceFieldsAreDirty) {
    // See MAIL-221
    return 'Select all audience fields and save your mailing to see a list of scheduled and/or sent emails.';
  }

  if (getRecipientsStatus === LOADING_STATUS.SUCCESS) {
    if (recipientsList && recipientsList.length === 0) {
      return 'There are no recipients for your defined audience.';
    }
  }

  if (!readyToGetRecipients) {
    return 'Select all audience fields and save your mailing to see a list of scheduled and/or sent emails.';
  }

  // Returning undefined would show the default message while loading, but we
  // only want the skeleton to show.
  return '';
}
