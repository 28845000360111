import axios from 'axios';
import { backendURL } from '../config';

export default function callBackendApi(
  token,
  method,
  endpoint,
  data = undefined,
  extraConfig = {},
) {
  const url = `${backendURL}${endpoint.startsWith('/') ? '' : '/'}${endpoint}`;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const config = { headers, ...extraConfig };

  const methodCased = method.toLowerCase();
  if (!['get', 'delete', 'post', 'put'].includes(methodCased)) {
    throw new Error(`Unsupported method ${method}`);
  }
  return axios({
    method: methodCased, url, data: data ?? {}, ...config,
  });
}
