/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { useAuth0 } from '@auth0/auth0-react';
import MailingForm from '../MailingForm';
import { createNewMailing, getSingleMailing, resetSingleMailing } from '../../actions';
import Breadcrumb from '../Breadcrumb';
import { useSaladbar } from '../../saladbar';
import {
  FORM_SUBMISSION_REASON,
  determineCreateMailingFormSubmissionReason,
  mailingFormSubmissionMessage,
} from '../MailingForm/helpers/submission';
import ErrorPopin from '../ErrorPopin';
import { SENDING_NOTIFICATION_PROGRESS_INDICATOR } from '../../constants';
import { microfrontendURLPrefix } from '../../config';

const MailingCreate = (props) => {
  const { match } = props;

  const { getAccessTokenSilently, user } = useAuth0();
  const {
    enqueueInfoNotification,
    enqueueSuccessNotification,
    enqueueErrorNotification,
    removeNotification,
  } = useSaladbar();

  const formInitialValues = {
    name: '',
    state: '',
    subject: '',
    election: '',
    body: '',
    audienceTypes: [],
    shiftTypes: [],
    electorates: [],
    senderName: '',
    senderEmail: 'no-reply@greens.org.au',
    replyEmail: '',
    active: false,
    mailingtemplateId: null,
    testEmailAddressesStr: '',
    testEmailAddresses: [],
    editorState: EditorState.createEmpty(),
    createdByName: '',
    createdByEmail: '',
    createdBySub: '',
    isResendOnRosterchange: true,
    isDailyPrepoll: false,
    dailyPrepollTime: null,
    isPeriodic: false,
    isSendOnlyOnce: false,
  };

  const createMailingStatus = useSelector((state) => state.mailing.createMailingStatus);
  const createMailingFailReason = useSelector((state) => state.mailing.createMailingFailReason);
  // eslint-disable-next-line no-unused-vars
  const getSingleMailingData = useSelector((state) => state.mailing.getSingleMailing);
  const openOnTab = useSelector((state) => {
    const hash = state?.router?.location?.hash;
    return (hash === undefined || hash === '') ? undefined : hash.substring(1);
  });

  const dispatch = useDispatch();
  const [copyMailingInfo, setCopyMailingInfo] = useState(formInitialValues);
  const [cleanSaveEnable, setCleanSaveEnable] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formSubmitHandler = async (values, initialValues, _formik, currentTab) => {
    const sanitisedValues = {
      ...values,
      scheduleStart: values.scheduleStart ?? '',
      scheduleStop: values.scheduleStop ?? '',
      scheduleOnce: values.scheduleOnce ?? '',
      createdByName: user.name,
      createdByEmail: user.email,
      createdBySub: user.sub,
    };

    const reason = determineCreateMailingFormSubmissionReason(sanitisedValues, initialValues);
    let sendingNotificationKey;
    if (reason === FORM_SUBMISSION_REASON.SEND_ONLY_ONCE) {
      sendingNotificationKey = enqueueInfoNotification('Sending...', {
        autoHideDuration: null,
        progressIndicator: SENDING_NOTIFICATION_PROGRESS_INDICATOR,
      });
    }

    try {
      const token = await getAccessTokenSilently();
      const mailingId = await dispatch(createNewMailing(token, sanitisedValues));
      enqueueSuccessNotification(
        mailingFormSubmissionMessage(
          'create',
          true,
          sanitisedValues,
          initialValues,
          null,
          reason,
        ),
      );
      if (mailingId) {
        dispatch(resetSingleMailing());
        dispatch(push(`${microfrontendURLPrefix}/mailings/${mailingId}#${currentTab}`));
      }
    } catch (err) {
      enqueueErrorNotification(mailingFormSubmissionMessage(
        'create',
        false,
        sanitisedValues,
        initialValues,
        err,
        reason,
      ));
    } finally {
      // We wait until we've queued the next notification before removing the old one
      if (sendingNotificationKey) removeNotification(sendingNotificationKey);
    }
  };

  useEffect(() => {
    const mailingId = match?.params?.mailingId ?? 0;
    const startGetSingleMailing = async () => {
      const token = await getAccessTokenSilently();
      if (Number.isInteger(Number(mailingId, 10))) {
        if (mailingId !== 0) {
          dispatch(getSingleMailing(token, mailingId));
        }
      } else {
        setErrorMessage('mailing not an integer');
      }
    };

    startGetSingleMailing();
  }, [dispatch, match, getAccessTokenSilently]);

  useEffect(() => {
    const mailing = { ...getSingleMailingData };
    const mailingId = match?.params?.mailingId ?? 0;
    if (mailingId) {
      const otherMailingData = async () => {
        if (mailing?.errors?.length
          && mailing.errors[0].name === 'EntityNotFoundError') {
          setErrorMessage(mailing.errors[0].message);
        } else if (mailing?.electorates) {
          mailing.active = false;
          delete mailing.createdAt;
          delete mailing.hasStartedDeliveries;
          delete mailing.id;
          delete mailing.isRemoved;
          mailing.isSendOnlyOnce = false;
          mailing.isPeriodic = false;
          delete mailing.removedAt;
          delete mailing.updatedAt;
          mailing.isDailyPrepoll = false;
          mailing.name = `Copy of ${mailing.name}`;
          mailing.electorates = mailing?.electorates?.map((x) => x?.id || x) ?? [];
          mailing.createdByName = '';
          mailing.createdByEmail = '';
          mailing.createdBySub = '';
          mailing.scheduleStart = null;
          mailing.scheduleStop = null;
          mailing.scheduleOnce = null;
          mailing.isResendOnRosterchange = true;
          mailing.dailyPrepollTime = null;
          setCopyMailingInfo(mailing);
          setCleanSaveEnable(true);
        }
      };
      otherMailingData();
    }
  }, [getSingleMailingData, match, getAccessTokenSilently]);

  let copyOriginalId = parseInt(match?.params?.mailingId, 10);
  if (Number.isNaN(copyOriginalId)) {
    copyOriginalId = 0;
  }

  return (
    <>
      <Breadcrumb
        parents={
        [{
          path: microfrontendURLPrefix || '/',
          label: 'Mailings',
        }]
        }
        currentLabel="Create mailing"
      />
      {!errorMessage
      && (
      <MailingForm
        formInitialValues={copyMailingInfo}
        mailingStatus={createMailingStatus}
        mailingFailReason={createMailingFailReason}
        formSubmitHandler={formSubmitHandler}
        openOnTab={openOnTab}
        cleanSaveEnable={cleanSaveEnable}
        isNewObject
        copyOriginalId={copyOriginalId}
      />
      )}
      {errorMessage
      && (
        <ErrorPopin
          title="The page cannot be found"
          message="The URL you requested does not exist"
          handler={() => dispatch(push(`${microfrontendURLPrefix}/mailings`))}
          button="Home"
        />
      )}
    </>
  );
};

MailingCreate.propTypes = {
  // eslint-disable-next-line react/require-default-props
  match: PropTypes.any,
};

export default MailingCreate;
