/* eslint-disable no-unused-vars */
// only sending and not changing anything else etc
export const FORM_SUBMISSION_REASON = {
  SAVE: 'SAVE',
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
  SEND_ONLY_ONCE: 'SEND_ONLY_ONCE',
};

// We need this because we want to provide feedback to the user on the action
// they took, but there is a single form handler that can be triggered by
// multiple things.
//
// Until https://github.com/formium/formik/pull/2437 is merged, there isn't an
// (easy/non-hacky) way to provide some sort of context/metadata for the
// submission when it takes place. All we'd really need is the name of the
// button, but its not currently possible (the event isn't passed along).
//
// Luckily in our case have all the information to determine the reason for the
// form submission.

/**
 * Pick a suitable message to display to user after MailingForm has submitted,
 * based on the cause ('create'|'update') and success (true|false) and the form
 * values.
 *
 * @param   {string}   cause       'create' or 'update'
 * @param   {boolean}  success     Whether it worked or not
 * @param   {object}   formValues  The (sanitised) values from the form
 * @param   {object}   initialFormValues The values on form prior to modification
 * @param   {Error}    err         The error object in case of failure
 * @param   {FORM_SUBMISSION_REASON} precalcReason Precalculated reason
 *
 * @return  {string}              Useful human-readable message
 */
export function mailingFormSubmissionMessage(
  cause, success, formValues, initialFormValues, err = null, precalcReason = null,
) {
  if (cause === 'create') {
    const reason = (precalcReason
      || determineCreateMailingFormSubmissionReason(formValues, initialFormValues));
    if (success) return successfulCreationMessage(reason);
    return failedCreationMessage(reason, err);
  } if (cause === 'update') {
    const reason = (precalcReason
      || determineUpdateMailingFormSubmissionReason(formValues, initialFormValues));
    if (success) return successfulUpdateMessage(reason);
    return failedUpdateMessage(reason, err);
  }
  throw new Error(`Invalid cause ${cause}, only valid values: ['create', 'update']`);
}

function successfulCreationMessage(reason) {
  switch (reason) {
    case FORM_SUBMISSION_REASON.ACTIVATE: return 'Mailing activated';
    case FORM_SUBMISSION_REASON.DEACTIVATE: return 'Mailing deactivated';
    case FORM_SUBMISSION_REASON.SEND_ONLY_ONCE: return 'Mailing sent';
    case FORM_SUBMISSION_REASON.SAVE: // Fall through
    default: return 'Mailing saved';
  }
}

function successfulUpdateMessage(reason) {
  switch (reason) {
    case FORM_SUBMISSION_REASON.ACTIVATE: return 'Mailing activated';
    case FORM_SUBMISSION_REASON.DEACTIVATE: return 'Mailing deactivated';
    case FORM_SUBMISSION_REASON.SEND_ONLY_ONCE: return 'Mailing sent';
    case FORM_SUBMISSION_REASON.SAVE: // Fall through
    default: return 'Mailing saved';
  }
}

function failedCreationMessage(reason, _err) {
  // We don't currently use err, but it may be useful in future
  switch (reason) {
    case FORM_SUBMISSION_REASON.ACTIVATE: return 'Mailing failed to activate!';
    case FORM_SUBMISSION_REASON.DEACTIVATE: return 'Mailing failed to deactivate!';
    case FORM_SUBMISSION_REASON.SEND_ONLY_ONCE: return 'Mailing failed to send!';
    case FORM_SUBMISSION_REASON.SAVE: // Fall through
    default: return 'Mailing failed to save!';
  }
}

function failedUpdateMessage(reason, _err) {
  switch (reason) {
    case FORM_SUBMISSION_REASON.ACTIVATE: return 'Mailing failed to activate!';
    case FORM_SUBMISSION_REASON.DEACTIVATE: return 'Mailing failed to deactivate!';
    case FORM_SUBMISSION_REASON.SEND_ONLY_ONCE: return 'Mailing failed to send!';
    case FORM_SUBMISSION_REASON.SAVE: // Fall through
    default: return 'Mailing failed to save!';
  }
}

// Previously this file exported the functions below instead, which is why they
// have documentation.

/**
 * Determines the reason/context for the updating of an existing mailing during
 * form submission, inferred from the form values being submitted. Correlates
 * with "which button did the user press?" See source file for explanation of
 * why this is necessary.
 *
 * ASSUMES the values are a valid combination and it actually makes sense. E.g.
 * technically you can't do a send once off mailing a second time, but we don't
 * check that here.
 *
 * @param   {object}  values  The (sanitised) values from the form
 * @param   {object} previousValues The values prior to form modification
 *
 * @return  {string} One of the 3 possible values in FORM_SUBMISSION_REASON
 * ]
 */
export function determineUpdateMailingFormSubmissionReason(values, previousValues) {
  // At the moment its the same for both updating and saving, but might change
  // in future
  return determineMailingFormSubmissionReason(values, previousValues);
}

/**
 * Determines the reason/context for the creation of a new mailing during form
 * submission, inferred from the form values being submitted. Correlates with
 * "which button did the user press?" See source file for explanation of why
 * this is necessary.

 * ASSUMES the values are a valid combination and it actually makes sense.
 *
 * @param   {object}  values  The (sanitised) values from the form
 *
 * @return  {string}          One of: ['save', 'activate', 'sendOnlyOnce']
 */
export function determineCreateMailingFormSubmissionReason(values, previousValues) {
  // At the moment its the same for both updating and saving, but might change
  // in future
  return determineMailingFormSubmissionReason(values, previousValues);
}

// Its actually very very simple logic, but things might change in future
function determineMailingFormSubmissionReason(values, previousValues = {}) {
  if (values.isSendOnlyOnce
    && !previousValues.active
    && values.active
    && !values.scheduleOnce) {
    return FORM_SUBMISSION_REASON.SEND_ONLY_ONCE;
  }

  if (previousValues.active && !values.active) return FORM_SUBMISSION_REASON.DEACTIVATE;

  if (!previousValues.active && values.active) return FORM_SUBMISSION_REASON.ACTIVATE;

  return FORM_SUBMISSION_REASON.SAVE;
}
