import React, { useEffect } from 'react';
import {
  Route, Switch, withRouter,
} from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import isNil from 'lodash/isNil';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import * as Sentry from '@sentry/react';
import { useErrorHandler } from 'react-error-boundary';
import NoAuthNeededTest from './NoAuthNeededTest';
import MailingCreate from './MailingCreate';
import MailingView from './MailingView';
import MailingList from './MailingList';
import NotFound from './NotFound';
import AuthRequiredRoute from './auth/AuthRequiredRoute';
import {
  auth0Domain, auth0ClientId, auth0Audience, spaMode,
  microfrontendURLPrefix, isSentryDisabled,
} from '../config';
import { saveUser } from '../actions';
import Token from './Token';
import Navbar from './Navbar';
import styles from './styles.module.scss';
import { SaladbarProvider } from '../saladbar';
import setupAxiosInterceptors from './axiosInterceptors';
import { BACKEND_DOWN_REASON, BACKEND_STATUS } from '../constants';
import BackendError from '../errors/BackendError';
import BackendServerUncontactableError from '../errors/BackendServerUncontactableError';
import BackendCiviCrmUncontactableError from '../errors/BackendCiviCrmUncontactableError';
import BackendDatabaseUncontactableError from '../errors/BackendDatabaseUncontactableError';

let setupAxiosInterceptorsCalled = false;
const setupAxiosInterceptorsOnce = () => {
  if (!setupAxiosInterceptorsCalled) {
    setupAxiosInterceptorsCalled = true;
    setupAxiosInterceptors();
  }
};

const App = () => {
  const { user } = useAuth0();
  const currentUser = useSelector((state) => state.userDetail);
  const backendStatus = useSelector((state) => state.backendStatus);
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  // We only need to add the interceptors once, even if the App component is
  // unmounted and re-mounted due to attempted recovery from an error. However
  // we also don't want to call it before the first render either, so we still
  // need a useEffect, restricted to to the first render on each mount.
  useEffect(setupAxiosInterceptorsOnce, []);

  useEffect(() => {
    // If the backend status has changed, then trigger the error handler of the
    // error boundary. It can't detect errors outside of the react lifecycle,
    // which is where our api calls take place. Instead of using handleError we
    // could also just throw an Error and it would be caught, but its not
    // conceptually the same sort of error as ones that happen elsewhere.
    if (backendStatus.status === BACKEND_STATUS.DOWN) {
      // Technically the messages in each error are more or less useless, since
      // the exactly error class is checked and messages created during render.
      switch (backendStatus.downReason) {
        case BACKEND_DOWN_REASON.SERVER_UNCONTACTABLE:
          handleError(new BackendServerUncontactableError('Unable to contact the backend server'));
          break;
        case BACKEND_DOWN_REASON.CIVI_CRM_UNCONTACTABLE:
          handleError(new BackendCiviCrmUncontactableError('The backend server is running, but is unable to contact CiviCRM'));
          break;
        case BACKEND_DOWN_REASON.DATABASE_UNCONTACTABLE:
          handleError(new BackendDatabaseUncontactableError('The backend server is running, but is unable to contact the database it needs to work.'));
          break;
        case BACKEND_DOWN_REASON.UNKNOWN:
        default:
          handleError(new BackendError('Unknown issue'));
      }
    }
  }, [backendStatus, handleError]);

  useEffect(() => {
    if (isNil(currentUser) && !isNil(user)) {
      dispatch(saveUser(user));
    }
  }, [dispatch, currentUser, user]);

  return (
    <Switch>
      {/* Any routes using <AuthRequiredRoute> instead of <Route> will be
          redirected to login page */}

      {/* The index route doesn't make sense when a micro frontend, because
      its handled by container */}
      {spaMode === 'standalone' && <AuthRequiredRoute exact path="/" component={MailingList} />}
      {spaMode !== 'standalone' && <AuthRequiredRoute exact path={microfrontendURLPrefix} component={MailingList} /> }

      <AuthRequiredRoute exact path={`${microfrontendURLPrefix}/mailings`} component={MailingList} />
      <AuthRequiredRoute path={`${microfrontendURLPrefix}/mailings/copyfrom/:mailingId`} component={MailingCreate} />
      <AuthRequiredRoute exact path={`${microfrontendURLPrefix}/mailings/copyfrom`} component={MailingCreate} />
      <AuthRequiredRoute exact path={`${microfrontendURLPrefix}/mailings/create`} component={MailingCreate} />
      <AuthRequiredRoute path={`${microfrontendURLPrefix}/mailings/:mailingId`} component={MailingView} />
      <AuthRequiredRoute exact path={`${microfrontendURLPrefix}/token`} component={Token} />
      <Route path={`${microfrontendURLPrefix}/no_auth_needed`} component={NoAuthNeededTest} />
      <Route component={NotFound} />
    </Switch>
  );
};

const AppWithAuth0 = () => (
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: auth0Audience,
    }}
  >
    <main>
      <Navbar />
      <Container className={styles.container}>
        <SaladbarProvider>
          <App />
        </SaladbarProvider>
      </Container>
    </main>
  </Auth0Provider>
);

const defaultExport = isSentryDisabled
  ? withRouter(AppWithAuth0)
  : Sentry.withProfiler(withRouter(AppWithAuth0));

export default defaultExport;
