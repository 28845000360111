import React, { useEffect } from 'react';
import moment from 'moment';
import { integer } from 'airbnb-prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button, Link, IconButton, Box,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import TruncatedCsv from '../TruncatedCsv';
import {
  getMailingList,
  resetCreateMailingStatus,
  resetRecipients,
  resetDeliveries,
  resetUpdateMailingStatus,
  deleteExistingMailing,
} from '../../actions';
import {
  LOADING_STATUS,
  DEFAULT_MAILING_LIST_MAX_ELECTORATES_IN_CELL,
  DEFAULT_MAILING_LIST_MAX_AUDIENCE_TYPES_IN_CELL,
} from '../../constants';
import RosterMailerTable from '../fields/RosterMailerTable';
import mapMailingsListToTableData from './helpers/table';
import {
  microfrontendURLPrefix,
} from '../../config';

// Code based on https://material-ui.com/components/menus/
const initialActionMenuState = {
  mouseX: null,
  mouseY: null,
  enableDelete: null,
};

const MailingList = ({ maxElectoratesInCell, maxAudienceTypesInCell }) => {
  /** @type {Promise} */
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const mailingList = useSelector((state) => state.mailing.mailingList);
  const { user } = useAuth0();
  const userSub = user?.sub || '';

  const getMailingsStatus = useSelector((state) => state.mailing.getMailingsStatus);
  const [actionMenuState, setActionMenuState] = React.useState(initialActionMenuState);
  const [currentActionMenuAnchorEl, setCurrentActionMenuAnchorEl] = React.useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [copyDialogOpen, setCopyDialogOpen] = React.useState(false);

  const [mailingName, setMailingName] = React.useState('');
  const [mailingSubUser, setMailingSubUser] = React.useState('');
  const [mailingId, setMailingId] = React.useState('');
  const [mailingTableData, setmailingTableData] = React.useState([]);
  useEffect(() => {
    const retrieveMailings = async () => {
      const token = await getAccessTokenSilently();
      dispatch(getMailingList(token));
    };

    retrieveMailings();
  }, [dispatch, getAccessTokenSilently]);
  useEffect(() => {
    setmailingTableData(mapMailingsListToTableData(mailingList ?? []));
  }, [mailingList]);

  const handleActionMenuClick = (event,
    mailingIdIn,
    mailingNameIn,
    mailingSubUserIn,
    enableDelete) => {
    event.preventDefault();
    setCurrentActionMenuAnchorEl(event.currentTarget);
    setActionMenuState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      enableDelete,
    });
    setMailingName(mailingNameIn);
    setMailingId(mailingIdIn);
    setMailingSubUser(mailingSubUserIn);
  };

  const handleContextClose = () => {
    setCurrentActionMenuAnchorEl(null);
    setActionMenuState(initialActionMenuState);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogDelete = async () => {
    const token = await getAccessTokenSilently();
    await dispatch(deleteExistingMailing(token, mailingId));
    dispatch(getMailingList(token));
    setDeleteDialogOpen(false);
  };

  const activateDelete = () => {
    handleDeleteDialogOpen();
    handleContextClose();
  };

  const handleCopyDialogOpen = () => {
    setCopyDialogOpen(true);
  };

  const handleCopyDialogClose = () => {
    setCopyDialogOpen(false);
  };

  const handleCopyDialogCopy = () => {
    setCopyDialogOpen(false);
    dispatch(resetCreateMailingStatus());
    dispatch(resetRecipients());
    dispatch(resetDeliveries());
    dispatch(push(`${microfrontendURLPrefix}/mailings/copyfrom/${mailingId}`));
  };

  const activateCopy = () => {
    handleCopyDialogOpen();
    handleContextClose();
  };

  const MAILINGS_TABLE_COLUMNS = [
    {
      title: 'Name',
      field: 'name',
      align: 'left',
      render: (mailing) => (
        <Link
          color="primary"
          href={`${microfrontendURLPrefix}/mailings/${mailing.id}`}
          onClick={(event) => {
            event.preventDefault();
            dispatch(resetUpdateMailingStatus());
            dispatch(resetRecipients());
            dispatch(resetDeliveries());
            dispatch(push(`${microfrontendURLPrefix}/mailings/${mailing.id}`));
          }}
        >
          {mailing.name}
        </Link>
      ),
      filterPlaceholder: 'Filter by...',
    },
    {
      title: 'State', field: 'state', align: 'left', filterPlaceholder: 'Filter by...',
    },
    {
      title: 'Election', field: 'electionName', align: 'left', filterPlaceholder: 'Filter by...',
    },
    {
      title: 'Electorates',
      // The field needs to be the full string to allow proper filtering by the
      // full cell contents, not just what the user can see
      field: 'electorateNamesSortedCsv',
      align: 'left',
      filterPlaceholder: 'Filter by...',
      render: (mailing) => (
        <TruncatedCsv
          items={mailing.electorateNamesSorted}
          maxItems={maxElectoratesInCell}
        />
      ),
    },
    {
      title: 'Shifts',
      field: 'shiftTypesSortedCsv',
      align: 'left',
      filterPlaceholder: 'Filter by...',
    },
    {
      title: 'Audience',
      field: 'audienceTypesSortedCsv',
      align: 'left',
      filterPlaceholder: 'Filter by...',
      render: (mailing) => (
        <TruncatedCsv
          items={mailing.audienceTypesSorted}
          maxItems={maxAudienceTypesInCell}
        />
      ),
    },
    {
      title: 'Created by',
      field: 'createdByName',
      align: 'left',
      filterPlaceholder: 'Filter by...',
    },
    {
      title: 'Status',
      field: 'derivedMailingStatus',
      align: 'left',
      filterPlaceholder: 'Filter by...',
      render: (mailing) => (
        <Box component="span" color={mailing.derivedMailingStatus === 'Inactive*' ? 'error.main' : 'text.primary'}>
          {mailing.derivedMailingStatus}
        </Box>
      ),
    },
    {
      title: '',
      field: 'sentAt',
      align: 'left',
      filtering: false,
      render: (mailing) => (
        <>
          <IconButton
            id={`${mailing.id}-open-action-menu`}
            onClick={(evt) => handleActionMenuClick(evt,
              mailing.id,
              mailing.name,
              mailing.createdBySub,
              (!mailing.hasStartedDeliveries && !mailing.isDailyPrepoll)
              || (mailing.isDailyPrepoll
                && mailing.countDeliveryResult.filter(
                  (x) => {
                    if (x.count > 0
                    && ['sent', 'error'].includes(x.deliveryStatus)
                    ) {
                      return true;
                    }
                    return false;
                  },
                ).length === 0
                && moment(mailing.electionDate).isAfter(moment())))}
            aria-label="open-action-menu"
            aria-haspopup="true"
            aria-controls={`${mailing.id}-action-menu`}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`${mailing.id}-action-menu`}
            open={Boolean(currentActionMenuAnchorEl)}
            keepMounted
            anchorReference="anchorPosition"
            onClose={handleContextClose}
            anchorPosition={
              actionMenuState.mouseY && actionMenuState.mouseX
                ? { top: actionMenuState.mouseY, left: actionMenuState.mouseX }
                : { top: 200, left: 200 }
            }
          >
            <MenuItem
              onClick={() => activateCopy()}
            >
              Copy
            </MenuItem>
            <MenuItem
              disabled={actionMenuState.enableDelete ? null : true}
              onClick={() => activateDelete()}
            >
              Delete
            </MenuItem>

          </Menu>
        </>
      ),

    },
  ];

  const emptyMailingListMessage = mailingTableData.length
    ? 'There are no mailings that satisfy the criteria.'
    : 'There are no mailings to display.';

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch(resetCreateMailingStatus());
          dispatch(resetRecipients());
          dispatch(resetDeliveries());
          dispatch(push(`${microfrontendURLPrefix}/mailings/create`));
        }}
      >
        Create a new Mailing
      </Button>

      <p />
      <p />
      <RosterMailerTable
        containerTestId="mailings-table-container"
        columns={MAILINGS_TABLE_COLUMNS}
        data={mailingTableData}
        emptyTableMessage={emptyMailingListMessage}
        isLoading={[LOADING_STATUS.INIT, LOADING_STATUS.LOADING].includes(getMailingsStatus)}
        options={{ pageSize: 20 }}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-confirm-dialog-title"
        aria-describedby="delete-confirm-dialog-description"
        overlaystyle={{ backgroundColor: 'transparent' }}
      >
        <DialogTitle id="delete-confirm-dialog-title">Delete this mailing?</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirm-dialog-description">
            Are you sure you want to delete the mailing
            {' '}
            <strong>{mailingName}</strong>
            ?

            {mailingSubUser !== userSub && (
            <Box component="span" color="error.main">
              <br />
              <br />
              <strong>
                <em>
                  Warning: this mailing was created by another user.
                </em>
              </strong>
            </Box>
            )}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteDialogDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={copyDialogOpen}
        onClose={handleCopyDialogClose}
        aria-labelledby="copy-confirm-dialog-title"
        aria-describedby="copy-confirm-dialog-description"
        overlaystyle={{ backgroundColor: 'transparent' }}
      >
        <DialogTitle id="copy-confirm-dialog-title">Copy this mailing?</DialogTitle>
        <DialogContent>
          <DialogContentText id="copy-confirm-dialog-description">
            Are you sure you want to copy the mailing
            {' '}
            <strong>{mailingName}</strong>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCopyDialogCopy} color="primary" autoFocus>
            Copy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MailingList.defaultProps = {
  maxElectoratesInCell: DEFAULT_MAILING_LIST_MAX_ELECTORATES_IN_CELL,
  maxAudienceTypesInCell: DEFAULT_MAILING_LIST_MAX_AUDIENCE_TYPES_IN_CELL,
};

MailingList.propTypes = {
  maxElectoratesInCell: integer(),
  maxAudienceTypesInCell: integer(),
};

export default MailingList;
