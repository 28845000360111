import React from 'react';
import { Link } from 'react-router-dom';
import {
  microfrontendURLPrefix,
} from '../config';

// Stateless functional component
function NoAuthNeededTest() {
  return (
    <div>
      <h2>
        This is a page that does not require auth.
      </h2>
      <Link to={microfrontendURLPrefix || '/'}>Go back</Link>
    </div>
  );
}

export default NoAuthNeededTest;
