import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';

const Token = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(undefined);

  const getToken = async () => {
    setToken(await getAccessTokenSilently());
  };
  return (
    <div>
      <Button variant="contained" color="primary" onClick={getToken}>Get token</Button>
      <div style={{ width: '100%', overflowWrap: 'break-word' }}>{token}</div>
    </div>
  );
};

export default Token;
