import React from 'react';
import { get, find } from 'lodash';
import PropTypes from 'prop-types';
import {
  FormControl, MenuItem, Select, Chip, Input, InputLabel, makeStyles,
} from '@material-ui/core';
import './styles.scss';

const MultiSelectControl = ({
  name, value, onChange, placeholder, dataItems, variant, label, fullWidth, disabled,
}) => {
  const useStyles = makeStyles(() => ({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: '2px',
    },
  }));
  const classes = useStyles();

  return (
    <FormControl fullWidth={fullWidth} variant={variant}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        multiple
        labelId={`${name}-label`}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((item) => {
              const chipLabel = get(find(dataItems, { value: item }), 'label');
              return (
                <Chip className={classes.chip} key={item} label={chipLabel} />
              );
            })}
          </div>
        )}
        disabled={disabled}
      >
        { placeholder !== undefined && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        )}
        {dataItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
MultiSelectControl.defaultProps = {
  placeholder: undefined,
  variant: undefined,
  dataItems: [],
  fullWidth: false,
  disabled: undefined,
};

MultiSelectControl.propTypes = {
  dataItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MultiSelectControl;
