import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import ErrorPopin from './ErrorPopin';
import {
  microfrontendURLPrefix,
} from '../config';

// Not so Stateless functional component
const NotFound = () => {
  const dispatch = useDispatch();

  return (
    <ErrorPopin
      title="The page cannot be found"
      message="The URL you requested does not exist"
      handler={() => dispatch(push(`${microfrontendURLPrefix}/mailings`))}
      button="Home"
    />
  );
};

export default NotFound;
