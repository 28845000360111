import axios from 'axios';
import { logger } from '../helpers';
import { getStore } from '../store';
import { setBackendStatusDown } from '../actions';
import { BACKEND_DOWN_REASON } from '../constants';

// The documentation for interceptors is somewhat lacking, but a temporary
// unofficial but enhanced document is available here:
// https://github.com/chinesedfan/You-Dont-Know-Axios#interceptors
// In particular it is clearer about use of promises
// Also note that at time of writing we were using Axios 0.21.1 and the
// {synchronous: true} option wasn't available; its mentioned on the master
// branch of github but that isn't the latest release, its the next release.

// Any status code that lie within the range of 2xx cause this function to
// trigger. We pass it through unchanged.
const handleFulfilled = (response) => response;

// Do something with response error
const handleRejected = async (error) => {
  logger.debug(`axiosInterceptor caught error: ${error.name}. Message: ${error.message}`);

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const statusCode = error.response.status ?? 500;

    if (statusCode === 404) {
      return error.response;
    }

    if (statusCode >= 400 && statusCode < 500) {
      // A client error. Re-throw/reject, that is handled in a different manner.
    } else {
      // TODO: Have server include information about the cause, so we can be specific
      // For now we do a general one
      getStore().dispatch(setBackendStatusDown(BACKEND_DOWN_REASON.UNKNOWN));

      // if (???) {
      //   getStore().dispatch(setBackendStatusDown(BACKEND_DOWN_REASON.CIVI_CRM_UNCONTACTABLE));
      // } else if (???) {
      //   getStore().dispatch(setBackendStatusDown(BACKEND_DOWN_REASON.DATABASE_UNCONTACTABLE));
      // } else {
      //   getStore().dispatch(setBackendStatusDown(BACKEND_DOWN_REASON.UNKNOWN));
      // }
    }

    return error.response;
  }

  if (error.request) {
    // The request was made but no response was received `error.request` is an
    // instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    getStore().dispatch(setBackendStatusDown(BACKEND_DOWN_REASON.SERVER_UNCONTACTABLE));
    return error.response;
  }

  // If it doesn't match those, that is bad, but we don't have an appropriate
  // action to dispatch, so re-throw/reject
  throw error;
};

const requestInterceptorIds = [];
const responseInterceptorIds = [];

/**
 * Adds the axios interceptors to handle errors etc. Should only be called once.
 * Will throw an Error if called more than once, unless resetAxiosInterceptors()
 * is called first.
 */
export default function setupAxiosInterceptors() {
  if (requestInterceptorIds.length > 0 + responseInterceptorIds.length > 0) {
    throw new Error('setupAxiosInterceptors() called a second time. This should not happen');
  }

  const id = axios.interceptors.response.use(handleFulfilled, handleRejected);
  requestInterceptorIds.push(id);
}

/**
 * For use in tests that make use of the whole app that will include calls to
 * setupAxiosInterceptors() separately for each test. Can be used instead of
 * just mocking setupAxiosInterceptors.
 */
export function resetAxiosInterceptors() {
  if (process.env.NODE_ENV !== 'test') throw new Error('resetAxiosInterceptors() should only be called in test environment');

  while (requestInterceptorIds.length > 0) {
    axios.interceptors.request.eject(requestInterceptorIds.pop());
  }

  while (responseInterceptorIds.length > 0) {
    axios.interceptors.response.eject(responseInterceptorIds.pop());
  }
}
