// For MAIL-277: "Recipient and delivery information should only be reset
// if the audience changes."

export default function isResetRecipientsAndDelivery(oldValues, newValues) {
  if (oldValues.state !== newValues.state) {
    return true;
  }
  if (oldValues.election !== newValues.election) {
    return true;
  }

  const oldShiftTypesSorted = oldValues.shiftTypes.slice().sort();
  const newShiftTypesSorted = newValues.shiftTypes.slice().sort();
  if (oldShiftTypesSorted.length !== newShiftTypesSorted.length) {
    return true;
  }
  if (!oldShiftTypesSorted.every((value, index) => value === newShiftTypesSorted[index])) {
    return true;
  }

  const oldAudienceTypesSorted = oldValues.audienceTypes.slice().sort();
  const newAudienceTypesSorted = newValues.audienceTypes.slice().sort();
  if (oldAudienceTypesSorted.length !== newAudienceTypesSorted.length) {
    return true;
  }
  if (!oldAudienceTypesSorted.every((value, index) => value === newAudienceTypesSorted[index])) {
    return true;
  }

  const oldElectoratesSorted = oldValues.electorates.slice().sort();
  const newElectoratesSorted = newValues.electorates.slice().sort();
  if (oldElectoratesSorted.length !== newElectoratesSorted.length) {
    return true;
  }
  if (!oldElectoratesSorted.every((value, index) => value === newElectoratesSorted[index])) {
    return true;
  }

  return false;
}
