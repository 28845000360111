import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';

function isReady(dataItems) {
  const ready = dataItems !== undefined
  && dataItems !== null
  && dataItems.length !== 0;
  return ready;
}

const SelectControl = ({
  name, value, onChange, dataItems, variant, disabled, label,
}) => (
  <FormControl fullWidth variant={variant}>
    <InputLabel id={`${name}-label`} htmlFor={name}>{label}</InputLabel>
    <Select
      labelId={`${name}-label`}
      name={name}
      value={isReady(dataItems) ? value : ''}
      onChange={onChange}
      displayEmpty
      disabled={disabled}
    >
      {dataItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

SelectControl.defaultProps = {
  value: undefined,
  variant: undefined,
  dataItems: [],
  disabled: undefined,
};

SelectControl.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,

  dataItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export default SelectControl;
