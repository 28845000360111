import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config, {
  environment, sentryDsn, appVersion, spaMode, microfrontendURLPrefix, isSentryDisabled,
} from './config';
import createStore from './store';
import { fed21Theme } from './theme';
import App from './components/App';
import TopLevelBlockingErrorBoundary from './components/TopLevelBlockingErrorBoundary';
import './css/style.css';
import { logger } from './helpers';

logger.info('>>>>>>>>>>>>>>>STARTUP<<<<<<<<<<<<<<<');
logger.info(`spaMode = ${spaMode}`);
logger.info(`microfrontendURLPrefix = ${microfrontendURLPrefix}`);
logger.info(`App version = ${appVersion}`);
logger.info(`isSentryDisabled = ${isSentryDisabled}`);
logger.debug('config', config);

if (!isSentryDisabled) {
  // If sentryDsn is not set, the sdk just won't do anything
  if (sentryDsn) logger.debug('Start initialising Sentry...');
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    environment,
    tracesSampleRate: 0.2,
  });

  if (sentryDsn) logger.debug('...Finished initialising Sentry');
}

if (environment !== 'prod') {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000);
  });
}

function renderApp(containerId, history) {
  // eslint-disable-next-line no-console
  console.log(`HELLO, yes this is the rostermailer-frontend! REACT_APP_MAILER_ENV = ${process.env.REACT_APP_MAILER_ENV}`);
  const SentryInnerCore = isSentryDisabled
    ? (
      <Provider store={createStore(history)}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    )

    : (
      <TopLevelBlockingErrorBoundary>
        <Provider store={createStore(history)}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>
      </TopLevelBlockingErrorBoundary>

    );

  ReactDOM.render(
    <ThemeProvider theme={fed21Theme}>
      {SentryInnerCore}
    </ThemeProvider>,
    document.getElementById(containerId),
  );
}

function unmountApp(containerId) {
  const unmountedElement = document.getElementById(containerId);
  if (unmountedElement) {
    ReactDOM.unmountComponentAtNode(unmountedElement);
  }
}

if (spaMode === 'microfrontend') {
  // This global function is the primary micro frontend contract with the
  // container application
  // TODO: should this be defined in .env instead? I don't think so...?
  window.rostermailerFrontendJsRender = renderApp;
  window.rostermailerFrontendJsUnmount = unmountApp;
} else if (spaMode === 'standalone') {
  renderApp('root', createBrowserHistory());
} else {
  throw new Error('config.spaMode is set incorrectly. Can only be \'microfrontend\' or \'standalone\'');
}
