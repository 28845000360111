import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import TextField from '../fields/TextField';
import SelectControl from '../fields/SelectControl';
import MultiSelectControl from '../fields/MultiSelectControl';
import { getElectionList } from '../../actions';
import { SHIFTS, STATES, AUDIENCE_TYPES } from '../../constants';
import { electionFilterMonthsInPast } from '../../config';

// eslint-disable-next-line no-unused-vars
const MailingSetup = ({ formik, isNewObject, copyOriginalId }) => {
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();
  const electionList = useSelector((redux) => redux.elections.electionList) ?? [];
  const electionDateCutoff = moment().subtract(electionFilterMonthsInPast, 'months');
  // eslint-disable-next-line no-unused-vars
  const electionListFiltered = (isNewObject && !copyOriginalId
    ? electionList.filter((x) => moment(x.electionDate, 'YYYY-MM-DD').isSameOrAfter(electionDateCutoff))
    : electionList.filter((x) => moment(x.electionDate, 'YYYY-MM-DD').isSameOrAfter(electionDateCutoff)
  || x.name === formik.initialValues.electionName)
  );

  useEffect(() => {
    const retrieveElections = async () => {
      const token = await getAccessTokenSilently();
      dispatch(getElectionList(token, formik.values.state));
    };

    if (formik.values.state) retrieveElections();
  }, [formik.values.state, dispatch, getAccessTokenSilently]);

  const formDisplayObject = (item) => ({
    value: item.id,
    label: item.name,
  });

  const stateItems = STATES.map((item) => ({
    value: item,
    label: item,
  }));

  const electionItems = electionListFiltered?.map(formDisplayObject);
  const shiftItems = SHIFTS.map(formDisplayObject);
  const audienceTypeItems = AUDIENCE_TYPES.map(formDisplayObject);

  const electorateItems = electionList
    ?.find((election) => election.id === formik.values.election)
    ?.electorates
    ?.map(formDisplayObject).sort((a, b) => a.label.localeCompare(b.label));

  const helperTextRequired = (field) => {
    if (formik.values[field]) return undefined;
    if (formik.errors && formik.errors[field]) return formik.errors[field];
    return '* Required';
  };

  function selectAllElectorates() {
    const allElectoratesInElection = electorateItems
      .map((x) => x.value);
    formik.setFieldValue('electorates', allElectoratesInElection);
  }

  function clearAllElectorates() {
    formik.setFieldValue('electorates', []);
  }

  const electionListDateMapping = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const election of electionList) {
    electionListDateMapping[election.id] = election.electionDate;
  }
  const currentCalcElectionDate = formik.values.election in electionListDateMapping
    ? electionListDateMapping[formik.values.election]
    : '';

  const hasPrepollMailingStartedDate = formik.values.electionDate
    ? formik.values.electionDate
    : currentCalcElectionDate;

  const hasPrepollMailingStarted = formik.values.hasStartedDeliveries
  && formik.values.isDailyPrepoll && moment(hasPrepollMailingStartedDate).isSameOrBefore(moment());

  return (
    <Grid container spacing={5}>
      <Grid item xs={5}>
        <Typography variant="h6"><label>Name the mailing</label></Typography>
        <TextField
          id="name"
          type="text"
          label="Name"
          fullWidth
          value={formik.values.name || ''}
          onChange={formik.handleChange}
          error={!!formik.errors?.name}
          helperText={helperTextRequired('name')}
          disabled={formik.values.active || hasPrepollMailingStarted}
        />
        <br />
        <br />
        <Typography variant="h6">Define your audience</Typography>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <SelectControl
              name="state"
              label="State"
              value={formik.values.state ?? ''}
              placeholder="Select a state"
              dataItems={stateItems}
              onChange={(v) => {
                formik.setFieldValue('election', '');
                formik.setFieldValue('electorates', []);
                return formik.handleChange(v);
              }}
              disabled={formik.values.active || hasPrepollMailingStarted}
            />
          </Grid>
          <Grid item xs={7}>
            <SelectControl
              name="election"
              value={formik.values.election ?? ''}
              placeholder="Select an election"
              label="Election"
              dataItems={electionItems}
              onChange={(v) => {
                formik.setFieldValue('electorates', []);
                return formik.handleChange(v);
              }}
              disabled={formik.values.state === '' || formik.values.active || hasPrepollMailingStarted}
            />
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={10}>
              <MultiSelectControl
                name="electorates"
                fullWidth
                label="Electorate(s)"
                value={formik.values.electorates || []}
                type="text"
                placeholder="Select one or more electorates"
                variant="outlined"
                dataItems={electorateItems}
                onChange={formik.handleChange}
                disabled={formik.values.election === '' || formik.values.active || hasPrepollMailingStarted}
              />
            </Grid>
            <Grid container spacing={3} item xs={2}>
              <Grid item>
                <Button
                  onClick={selectAllElectorates}
                  type="button"
                  variant="contained"
                  color="default"
                  size="small"
                  disabled={formik.values.election === '' || formik.values.active || hasPrepollMailingStarted}
                >
                  All
                </Button>
                <Grid item>
                  <Button
                    onClick={clearAllElectorates}
                    type="button"
                    variant="contained"
                    color="default"
                    size="small"
                    disabled={formik.values.active || hasPrepollMailingStarted}
                  >
                    None
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MultiSelectControl
              name="shiftTypes"
              fullWidth
              label="Shift type(s)"
              value={formik.values.shiftTypes || []}
              type="text"
              placeholder="Select a shift type"
              variant="outlined"
              dataItems={shiftItems}
              onChange={formik.handleChange}
              disabled={formik.values.active || hasPrepollMailingStarted}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectControl
              name="audienceTypes"
              fullWidth
              label="Audience type(s)"
              value={formik.values.audienceTypes || []}
              type="text"
              placeholder="Select one or more audience types"
              variant="outlined"
              dataItems={audienceTypeItems}
              onChange={formik.handleChange}
              disabled={formik.values.active || hasPrepollMailingStarted}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Grid item xs={12}>
          <Typography variant="h6">Email details</Typography>
          <TextField
            id="subject"
            type="text"
            fullWidth
            label="Email subject"
            value={formik.values.subject || ''}
            onChange={formik.handleChange}
            error={!!formik.errors?.subject}
            helperText={formik.errors?.subject}
            disabled={formik.values.active || hasPrepollMailingStarted}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="senderName"
            type="text"
            fullWidth
            label="Sender name"
            value={formik.values.senderName || ''}
            onChange={formik.handleChange}
            error={!!formik.errors?.senderName}
            helperText={formik.errors?.senderName}
            disabled={formik.values.active || hasPrepollMailingStarted}
          />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="senderEmail"
              type="text"
              fullWidth
              label="Sender email"
              value={formik.values.senderEmail || ''}
              onChange={formik.handleChange}
              error={!!formik.errors?.senderEmail}
              helperText={formik.errors?.senderEmail}
              disabled={formik.values.active || hasPrepollMailingStarted}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="replyEmail"
              type="text"
              fullWidth
              label="Reply-To email (if different to sender)"
              value={formik.values.replyEmail || ''}
              onChange={formik.handleChange}
              error={!!formik.errors?.replyEmail}
              helperText={formik.errors?.replyEmail}
              disabled={formik.values.active || hasPrepollMailingStarted}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MailingSetup.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      election: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      audienceTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      electorates: PropTypes.arrayOf(PropTypes.string).isRequired,
      senderName: PropTypes.string.isRequired,
      senderEmail: PropTypes.string.isRequired,
      replyEmail: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      hasStartedDeliveries: PropTypes.bool,
      isDailyPrepoll: PropTypes.bool,
      isPeriodic: PropTypes.bool,
      electionDate: PropTypes.string,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.string),
    initialValues: PropTypes.shape({
      electionName: PropTypes.string,
    }),
  }).isRequired,
  isNewObject: PropTypes.bool,
  copyOriginalId: PropTypes.number,

};

MailingSetup.defaultProps = {
  isNewObject: false,
  copyOriginalId: 0,
};

export default MailingSetup;
