import { ACTIONS } from '../actions';
import { LOADING_STATUS, FAIL_REASON } from '../constants';

const initialState = {
  createMailingStatus: LOADING_STATUS.INIT,
  createMailingFailReason: undefined,
  updateMailingStatus: LOADING_STATUS.INIT,
  updateMailingFailReason: undefined,
  deleteMailingStatus: LOADING_STATUS.INIT,
  deleteMailingFailReason: undefined,
  mailingList: undefined,
  getMailingsStatus: LOADING_STATUS.INIT,
  testMailingStatus: LOADING_STATUS.INIT,
  testMailingFailReason: undefined,
  getSingleMailingStatus: LOADING_STATUS.INIT,
  getSingleMailing: undefined,
  getSingleMailingFailReason: undefined,
};

export default (state = initialState, action) => {
  if (action.type === ACTIONS.CREATE_MAILING_START) {
    return { ...state, createMailingStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.CREATE_MAILING_SUCCESS) {
    return {
      ...state,
      createMailingStatus: LOADING_STATUS.SUCCESS,
      createMailingFailReason: undefined,
    };
  }
  if (action.type === ACTIONS.CREATE_MAILING_FAIL) {
    return {
      ...state,
      createMailingStatus: LOADING_STATUS.FAIL,
      createMailingFailReason: FAIL_REASON.UNKNOWN,
    };
  }
  if (action.type === ACTIONS.CREATE_MAILING_FAIL_NOT_READY_TO_ACTIVATE) {
    return {
      ...state,
      createMailingStatus: LOADING_STATUS.FAIL,
      createMailingFailReason: FAIL_REASON.NOT_READY_TO_ACTIVATE,
    };
  }
  if (action.type === ACTIONS.RESET_CREATE_MAILING_STATUS) {
    return {
      ...state,
      createMailingStatus: LOADING_STATUS.INIT,
      createMailingFailReason: undefined,
    };
  }

  if (action.type === ACTIONS.UPDATE_MAILING_START) {
    return { ...state, updateMailingStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.UPDATE_MAILING_SUCCESS) {
    return {
      ...state,
      updateMailingStatus: LOADING_STATUS.SUCCESS,
      updateMailingFailReason: undefined,
    };
  }
  if (action.type === ACTIONS.UPDATE_MAILING_FAIL) {
    return {
      ...state,
      updateMailingStatus: LOADING_STATUS.FAIL,
      updateMailingFailReason: FAIL_REASON.UNKNOWN,
    };
  }

  if (action.type === ACTIONS.DELETE_MAILING_START) {
    return { ...state, deleteMailingStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.DELETE_MAILING_SUCCESS) {
    return {
      ...state,
      deleteMailingStatus: LOADING_STATUS.SUCCESS,
      deleteMailingFailReason: undefined,
    };
  }
  if (action.type === ACTIONS.DELETE_MAILING_FAIL) {
    return {
      ...state,
      deleteMailingStatus: LOADING_STATUS.FAIL,
      deleteMailingFailReason: FAIL_REASON.UNKNOWN,
    };
  }

  if (action.type === ACTIONS.UPDATE_MAILING_FAIL_NOT_READY_TO_ACTIVATE) {
    return {
      ...state,
      updateMailingStatus: LOADING_STATUS.FAIL,
      updateMailingFailReason: FAIL_REASON.NOT_READY_TO_ACTIVATE,
    };
  }
  if (action.type === ACTIONS.RESET_UPDATE_MAILING_STATUS) {
    return {
      ...state,
      updateMailingStatus: LOADING_STATUS.INIT,
      updateMailingFailReason: undefined,
    };
  }

  if (action.type === ACTIONS.GET_MAILINGS_START) {
    return { ...state, getMailingsStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.GET_MAILINGS_SUCCESS) {
    return {
      ...state,
      getMailingsStatus: LOADING_STATUS.SUCCESS,
      mailingList: action.payload,
    };
  }
  if (action.type === ACTIONS.GET_MAILINGS_FAIL) {
    return { ...state, getMailingsStatus: LOADING_STATUS.FAIL };
  }

  if (action.type === ACTIONS.GET_SINGLE_MAILING_START) {
    return { ...state, getSingleMailingStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.GET_SINGLE_MAILING_SUCCESS) {
    return {
      ...state,
      getSingleMailingStatus: LOADING_STATUS.SUCCESS,
      getSingleMailing: action.payload,
      getSingleMailingFailReason: undefined,
    };
  }
  if (action.type === ACTIONS.GET_SINGLE_MAILING_FAIL) {
    return {
      ...state,
      getSingleMailingStatus: LOADING_STATUS.FAIL,
      getSingleMailing: undefined,
      getSingleMailingFailReason: action.payload,
    };
  }
  if (action.type === ACTIONS.RESET_SINGLE_MAILING) {
    return {
      ...state,
      getSingleMailingStatus: LOADING_STATUS.INIT,
      getSingleMailing: undefined,
      getSingleMailingFailReason: undefined,
    };
  }

  if (action.type === ACTIONS.TEST_MAILING_START) {
    return { ...state, testMailingStatus: LOADING_STATUS.LOADING };
  }
  if (action.type === ACTIONS.TEST_MAILING_SUCCESS) {
    return {
      ...state,
      testMailingStatus: LOADING_STATUS.SUCCESS,
      testMailingFailReason: undefined,
    };
  }
  if (action.type === ACTIONS.TEST_MAILING_FAIL) {
    return {
      ...state,
      testMailingStatus: LOADING_STATUS.FAIL,
      testMailingFailReason: FAIL_REASON.UNKNOWN,
    };
  }
  if (action.type === ACTIONS.RESET_TEST_MAILING_STATUS) {
    return {
      ...state,
      testMailingStatus: LOADING_STATUS.INIT,
      testMailingFailReason: undefined,
    };
  }

  return state;
};
