/* eslint-disable no-restricted-syntax */
const FIELDS_REQUIRED_FOR_ACTIVATION = [
  'state',
  'subject',
  'election',
  'body',
  'audienceTypes',
  'shiftTypes',
  'electorates',
  'senderName',
  'senderEmail',
  'scheduleStart',
  'scheduleStop',
];

const FIELDS_REQUIRED_FOR_SEND_ONLY_ONCE = [
  'state',
  'subject',
  'election',
  'body',
  'audienceTypes',
  'shiftTypes',
  'electorates',
  'senderName',
  'senderEmail',
];

const FIELDS_FORBIDDEN_FOR_SEND_ONLY_ONCE = [
  'scheduleStart',
  'scheduleStop',
];

const FIELDS_REQUIRED_FOR_GET_RECIPIENTS = [
  'id', 'name', // Because these are required for saving
  'state',
  'election',
  'audienceTypes',
  'shiftTypes',
  'electorates',
];

export function validateForActivation(values, errormessages) {
  const notFilledObj = createErrorsObjFromNotFilledRequiredFields(
    values,
    FIELDS_REQUIRED_FOR_ACTIVATION,
    'Must have a value',
  );
  // eslint-disable-next-line no-restricted-syntax
  // eslint-disable-next-line guard-for-in
  for (const errormessage in errormessages) {
    notFilledObj[errormessage] = errormessages[errormessage];
  }
  return notFilledObj;
}

export function validateForSendOnlyOnce(values) {
  const requireErrors = createErrorsObjFromNotFilledRequiredFields(
    values,
    FIELDS_REQUIRED_FOR_SEND_ONLY_ONCE,
    'Must be filled to send now once',
  );

  const forbiddenErrors = createErrorsObjFromFilledForbiddenFields(
    values,
    FIELDS_FORBIDDEN_FOR_SEND_ONLY_ONCE,
    'Must be empty to send now once',
  );

  return { ...requireErrors, ...forbiddenErrors };
}

export function validateForGetRecipients(values) {
  const errors = createErrorsObjFromNotFilledRequiredFields(
    values,
    FIELDS_REQUIRED_FOR_GET_RECIPIENTS,
    'Must be filled to retrieve recipients',
  );

  if (!('id' in values)) errors.id = 'Must be set to retrieve recipients';

  return errors;
}

function createErrorsObjFromNotFilledRequiredFields(values, requiredFields, message) {
  return Object.fromEntries(
    findNotFilledRequiredFields(values, requiredFields)
      .map((x) => [x, message]),
  );
}

function findNotFilledRequiredFields(values, requiredFields) {
  return requiredFields.filter((x) => isBlank(values[x]));
}

/**
 * Checks if x is a 'blank' value, which is either an empty string or array,
 * undefined or null. The idea is to be used to check if a form value is
 * blank/empty/not filled out.
 *
 * This differs from Lodash's isEmpty(), because that considers scalar values to
 * be empty.
 *
 * @param   {string|number|null|undefined}  x  The value to check
 *
 * @return  {boolean}     True if blank, otherwise false.
 */
export function isBlank(x) {
  return x === undefined || x === null || x.length === 0;
}

function createErrorsObjFromFilledForbiddenFields(values, requiredFields, message) {
  return Object.fromEntries(
    findFilledForbiddenFields(values, requiredFields)
      .map((x) => [x, message]),
  );
}

function findFilledForbiddenFields(values, requiredFields) {
  return requiredFields.filter((x) => isFilled(values[x]));
}

function isFilled(x) {
  return x !== undefined && x !== null && x.length > 0;
}
