import React from 'react';

// React-scripts will only include environment variables starting with REACT_APP
const {
  REACT_APP_VERSION,
  REACT_APP_GIT_HEAD, // Can't get this working yet

  REACT_APP_MAILER_BACKEND_URL,
  REACT_APP_MAILER_AUTH0_DOMAIN,
  REACT_APP_MAILER_AUTH0_CLIENT_ID,
  REACT_APP_MAILER_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SPA_MODE,
  REACT_APP_MICROFRONTEND_URL_PREFIX,
  REACT_APP_DISABLE_SENTRY,
} = process.env;

const STANDALONE = 'standalone';

export const appVersion = REACT_APP_VERSION;
export const appGitHead = REACT_APP_GIT_HEAD;

export const backendURL = REACT_APP_MAILER_BACKEND_URL;
export const auth0Domain = REACT_APP_MAILER_AUTH0_DOMAIN;
export const auth0ClientId = REACT_APP_MAILER_AUTH0_CLIENT_ID;
export const auth0Audience = REACT_APP_MAILER_BACKEND_URL;
export const environment = process.env.NODE_ENV === 'test' ? 'test' : REACT_APP_MAILER_ENV;
export const sentryDsn = REACT_APP_SENTRY_DSN;

// Environment-dependent variables
export const electionFilterMonthsInPast = environment === 'prod' ? 3 : 12;

export const spaMode = REACT_APP_SPA_MODE ?? STANDALONE;
export const microfrontendURLPrefix = spaMode === STANDALONE ? '' : REACT_APP_MICROFRONTEND_URL_PREFIX || '';
export const isSentryDisabled = REACT_APP_DISABLE_SENTRY === 'true';

export const supportedTokens = [
  {
    key: 'firstName',
    value: '{{FirstName}}',
    text: 'First Name',
    description: (
      <p>
        The first available of the recipient&apos;s nickname, first name, or &quot;Friend&quot;
      </p>
    ),
  },
  {
    key: 'lastName',
    value: '{{LastName}}',
    text: 'Last Name',
    description: (<p>The last name of the recipient</p>),
  },
  {
    key: 'emailAddress',
    value: '{{EmailAddress}}',
    text: 'Email Address',
    description: (<p>The email address for the recipient</p>),
  },
  {
    key: 'boothCaptain',
    value: '{{BoothCaptain}}',
    text: 'Booth Captain',
    description: (
      <p>
        The contact information for any booth captains on a per-booth basis
        <br />
        <br />
        <strong>Booth captain(s):</strong>
        <br />
        Sue Smith, 0410 000 000, sue.smith@fake.email.com
        <br />
        <br />
        Recipients who are booth captains will see:
        <br />
        <br />
        We&apos;ve shared these details with volunteers on your booth...
        <br />
        <strong>Booth captain(s):</strong>
        <br />
        Sue Smith, 0410 000 000, sue.smith@fake.email.com
      </p>),
  },
  {
    key: 'boothInstructions',
    value: '{{BoothInstructions}}',
    text: 'Booth Instructions',
    description:
    (
      <p>
        Electorate-specific instructions for all volunteers at polling booths.
        When instructions exist, the output will be:
        <br />
        <br />
        <strong>ALBANY booth instructions:</strong>
        {' '}
        Don&apos;t forget to wear a green t-shirt and bring sunscreen.
        <br />
        <br />
        When instructions don&apos;t exist, the output will be:
        <br />
        <br />
        <strong>ALBANY booth instructions:</strong>
        {' '}
        N/A
        <br />
        <br />
        For a booth shared between multiple electorates, the output is the instructions for the
        {' '}
        <em>home electorate</em>
        {' '}
        for the booth (generally the electorate which contains the booth), or
        {' '}
        &quot;N/A&quot; if those instructions don&apos;t exist.
      </p>
    ),
  },
  {
    key: 'electorateEmail',
    value: '{{ElectorateEmail}}',
    text: 'Electorate Email',
    description: (
      <p>
        Electorate-specific email addresses if volunteers need to contact their local campaign.
        Only use if you know these addresses already exist.
        The addresses take the form of
        {' '}
        <em>electoratename</em>
        @
        <em>state/territory</em>
        .greens.org.au
        <br />
        <br />
        <strong>ALBANY</strong>
        {' '}
        contact(s):
        If you need further information contact your local campaign at albany@wa.greens.org.au
      </p>
    ),
  },
  {
    key: 'roster',
    value: '{{Roster}}',
    text: 'Roster',
    description: (
      <p>
        The recipient&apos;s roster for each booth they are rostered to
        <br />
        <br />
        <strong>Date:</strong>
        {' '}
        Saturday, 7 August 2021
        <br />
        <strong>Booth premises:</strong>
        {' '}
        Woop-de-doo Primary School
        <br />
        <strong>Booth address:</strong>
        {' '}
        123 Alphabet St, Woop-de-doo, VIC 3234
        <br />
        <strong>Shift(s):</strong>
        {' '}
        8am-12pm, 2pm-6pm
        <br />
        <br />
        If the recipient has another role (any of captain, set up, pack up or scrutineer),
        they will have an extra &quot;Your role(s)&quot; line:
        <br />
        <br />
        <strong>Date:</strong>
        {' '}
        Saturday, 7 August 2021
        <br />
        <strong>Booth premises:</strong>
        {' '}
        Woop-de-doo Primary School
        <br />
        <strong>Booth address:</strong>
        {' '}
        123 Alphabet St, Woop-de-doo, VIC 3234
        <br />
        <strong>Your role(s):</strong>
        {' '}
        Scrutineer, Set Up
        <br />
        <strong>Shift(s):</strong>
        {' '}
        8am-12pm, 2pm-6pm
      </p>
    ),
  },
  {
    key: 'boothRoster',
    value: '{{BoothRoster}}',
    text: 'Booth Roster',
    description:
    (
      <p>
        The names, phone numbers and shift times for every volunteer
        at every booth captained by the recipient.
        Can only be used in mailings where booth captains are the sole mailing audience.
        <br />
        <br />
        <strong>Date:</strong>
        {' '}
        Saturday, 7 August 2021
        <br />
        <strong>Booth premises:</strong>
        {' '}
        Woop-de-doo Primary School
        <br />
        <strong>Booth address:</strong>
        {' '}
        123 Alphabet St, Woop-de-doo, VIC 3234
        <br />
        <strong>Your role(s):</strong>
        {' '}
        Scrutineer —
        {' '}
        <em>note</em>
        ; Captain, Set Up, Pack Up, Scrapper —
        {' '}
        <em>note</em>
        <br />
        <strong>Your shift(s):</strong>
        {' '}
        8am-12pm (
        <em>note</em>
        ), 2pm-6pm (
        <em>note</em>
        )
        <br />
        <strong>Key role(s):</strong>
        {' '}
        Stephen Smith (Set Up, Pack Up  —
        {' '}
        <em>note</em>
        ;
        {' '}
        Scrutineer —
        {' '}
        <em>note</em>
        ), Joan Smith (Scrutineer —
        {' '}
        <em>note</em>
        )
        <br />
        <strong>Volunteers:</strong>
        <br />
        Stephen Smith (0410 000 000) 12:00pm-2:00pm (
        <em>note</em>
        )
        <br />
        Joan Smith (0400 000 000) 2:00pm-4:00pm (
        <em>note</em>
        )
        <br />
        Jenny Smith (0400 000 000) 8:00am-10:00am (
        <em>note</em>
        )
      </p>
    ),
  },
];

// So can also be imported as a single object
export default {
  appVersion,
  appGitHead,
  backendURL,
  auth0Domain,
  auth0ClientId,
  auth0Audience,
  environment,
  sentryDsn,
  electionFilterMonthsInPast,
  supportedTokens,
  spaMode,
  microfrontendURLPrefix,
  isSentryDisabled,
};
