import moment from 'moment';

export default function deriveMailingStatus(mailing) {
  if (mailing.isSendOnlyOnce && !mailing.scheduleOnce) return 'Completed';
  let scheduleExceeded;
  if (mailing.isSendOnlyOnce) {
    scheduleExceeded = mailing.scheduleOnce && moment().isAfter(mailing.scheduleOnce, 'minute');
  } else {
    scheduleExceeded = mailing.scheduleStop && moment().isAfter(mailing.scheduleStop, 'minute');
  }

  if (mailing.isDailyPrepoll && moment(mailing.electionDate).isSameOrBefore(moment().format('YYYY-MM-DD'))) {
    return 'Completed';
  }

  if (mailing.active) {
    if (scheduleExceeded) return 'Completed';
    return 'Active';
  }

  if (scheduleExceeded) return 'Inactive*';

  return 'Inactive';
}
