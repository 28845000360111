import { ACTIONS } from '../actions';

const initialState = {};

export default (state = initialState, action) => {
  if (action.type === ACTIONS.SAVE_USER) {
    return { ...action.payload };
  }
  return state;
};
