import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import ErrorFallback from './ErrorFallback';
import { logger } from '../../helpers';

// eslint-disable-next-line no-unused-vars
const errorHandler = (error, _componentStack, eventId) => {
  logger.debug(`Sentry caught the error: name=${error.name}, eventId=${eventId}`);
};

const resetHandler = () => {
};

/**
 * Wrapper for Sentry's ErrorBoundary component, defining the behaviour we want
 * for the app, mainly the fallback component.
 */
const TopLevelBlockingErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={ErrorFallback}
    onReset={resetHandler}
    onError={errorHandler}
    showDialog
  >
    {children}
  </Sentry.ErrorBoundary>
);

// TopLevelBlockingErrorBoundary.defaultProps = {};

TopLevelBlockingErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TopLevelBlockingErrorBoundary;
