import {
  getElections,
  createMailing,
  updateMailing,
  getMailings,
  getMailing,
  getRecipients,
  getDeliveries,
  deleteMailing,
  getMailingTemplates,
  testMailing,
} from '../apis';
import {
  API_ERROR_MESSAGE_REGEX_NOT_READY_TO_ACTIVATE,
  BACKEND_STATUS,
} from '../constants';
import { logger } from '../helpers';

// Good explanation of thunks: https://daveceddia.com/what-is-a-thunk/
// Also https://stackoverflow.com/a/54066862, skip past the transpiling bit
// Basically:
// 1. Actions are just plain objects. They have a type and optionally payload.
// 2. Action creators just return actions (i.e. they don't have side effects)
// 3. Thunks are action creator functions that dispatch actions and have side
//    effects. Their execution is handled by the react-thunk middleware. They
//    can be synchronous or asynchronous, but main purpose is for async
//    behaviour.

export const ACTIONS = {
  SAVE_USER: 'SAVE_USER',
  CREATE_MAILING_START: 'CREATE_MAILING_START',
  CREATE_MAILING_SUCCESS: 'CREATE_MAILING_SUCCESS',
  CREATE_MAILING_FAIL: 'CREATE_MAILING_FAIL',
  CREATE_MAILING_FAIL_NOT_READY_TO_ACTIVATE: 'CREATE_MAILING_FAIL_NOT_READY_TO_ACTIVATE',
  RESET_CREATE_MAILING_STATUS: 'RESET_CREATE_MAILING_STATUS',
  UPDATE_MAILING_START: 'UPDATE_MAILING_START',
  UPDATE_MAILING_SUCCESS: 'UPDATE_MAILING_SUCCESS',
  UPDATE_MAILING_FAIL: 'UPDATE_MAILING_FAIL',
  UPDATE_MAILING_FAIL_NOT_READY_TO_ACTIVATE: 'UPDATE_MAILING_FAIL_NOT_READY_TO_ACTIVATE',
  RESET_UPDATE_MAILING_STATUS: 'RESET_UPDATE_MAILING_STATUS',
  GET_MAILINGS_START: 'GET_MAILINGS_START',
  GET_MAILINGS_SUCCESS: 'GET_MAILINGS_SUCCESS',
  GET_MAILINGS_FAIL: 'GET_MAILINGS_FAIL',
  GET_SINGLE_MAILING_START: 'GET_SINGLE_MAILING_START',
  GET_SINGLE_MAILING_SUCCESS: 'GET_SINGLE_MAILING_SUCCESS',
  GET_SINGLE_MAILING_FAIL: 'GET_SINGLE_MAILING_FAIL',
  RESET_SINGLE_MAILING: 'RESET_SINGLE_MAILING',
  DELETE_MAILING_START: 'DELETE_MAILING_START',
  DELETE_MAILING_SUCCESS: 'DELETE_MAILING_SUCCESS',
  DELETE_MAILING_FAIL: 'DELETE_MAILING_FAIL',
  GET_ELECTIONS_START: 'GET_ELECTIONS_START',
  GET_ELECTIONS_SUCCESS: 'GET_ELECTIONS_SUCCESS',
  GET_ELECTIONS_FAIL: 'GET_ELECTIONS_FAIL',
  GET_RECIPIENTS_START: 'GET_RECIPIENTS_START',
  GET_RECIPIENTS_SUCCESS: 'GET_RECIPIENTS_SUCCESS',
  GET_RECIPIENTS_FAIL: 'GET_RECIPIENTS_FAIL',
  RESET_RECIPIENTS: 'RESET_RECIPIENTS',
  GET_DELIVERIES_START: 'GET_DELIVERIES_START',
  GET_DELIVERIES_SUCCESS: 'GET_DELIVERIES_SUCCESS',
  GET_DELIVERIES_FAIL: 'GET_DELIVERIES_FAIL',
  RESET_DELIVERIES: 'RESET_DELIVERIES',
  GET_MAILINGTEMPLATES_START: 'GET_MAILINGTEMPLATES_START',
  GET_MAILINGTEMPLATES_SUCCESS: 'GET_MAILINGTEMPLATES_SUCCESS',
  GET_MAILINGTEMPLATES_FAIL: 'GET_MAILINGTEMPLATES_FAIL',
  RESET_MAILINGTEMPLATES: 'RESET_MAILINGTEMPLATES',
  TEST_MAILING_START: 'TEST_MAILING_START',
  TEST_MAILING_SUCCESS: 'TEST_MAILING_SUCCESS',
  TEST_MAILING_FAIL: 'TEST_MAILING_FAIL',
  RESET_TEST_MAILING_STATUS: 'RESET_TEST_MAILING_STATUS',
  BACKEND_STATUS_CHANGED: 'BACKEND_STATUS_CHANGED',
};

//
// SIMPLE (NON-THUNK) ACTION CREATORS
//

const createMailingStart = () => ({
  type: ACTIONS.CREATE_MAILING_START,
});

const createMailingSuccess = (mailingId) => ({
  type: ACTIONS.CREATE_MAILING_SUCCESS,
  payload: mailingId,
});

const createMailingFail = () => ({
  type: ACTIONS.CREATE_MAILING_FAIL,
});

const createMailingFailNotReadyToActivate = () => ({
  type: ACTIONS.CREATE_MAILING_FAIL_NOT_READY_TO_ACTIVATE,
});

const updateMailingStart = () => ({
  type: ACTIONS.UPDATE_MAILING_START,
});

const updateMailingSuccess = () => ({
  type: ACTIONS.UPDATE_MAILING_SUCCESS,
});

const updateMailingFail = () => ({
  type: ACTIONS.UPDATE_MAILING_FAIL,
});

const deleteMailingStart = () => ({
  type: ACTIONS.DELETE_MAILING_START,
});

const deleteMailingSuccess = () => ({
  type: ACTIONS.DELETE_MAILING_SUCCESS,
});

const deleteMailingFail = () => ({
  type: ACTIONS.DELETE_MAILING_FAIL,
});

const updateMailingFailNotReadyToActivate = () => ({
  type: ACTIONS.UPDATE_MAILING_FAIL_NOT_READY_TO_ACTIVATE,
});

const getMailingsStart = () => ({
  type: ACTIONS.GET_MAILINGS_START,
});

const getMailingsSuccess = (mailings) => ({
  type: ACTIONS.GET_MAILINGS_SUCCESS,
  payload: mailings,
});

const getMailingsFail = () => ({
  type: ACTIONS.GET_MAILINGS_FAIL,
});

const getSingleMailingStart = () => ({
  type: ACTIONS.GET_SINGLE_MAILING_START,
});

const getSingleMailingSuccess = (mailing) => ({
  type: ACTIONS.GET_SINGLE_MAILING_SUCCESS,
  payload: mailing,
});

const getSingleMailingFail = (err) => ({
  type: ACTIONS.GET_SINGLE_MAILING_FAIL,
  payload: err,
});

export const resetSingleMailing = () => ({
  type: ACTIONS.RESET_SINGLE_MAILING,
});

const getElectionsStart = () => ({
  type: ACTIONS.GET_ELECTIONS_START,
});

const getElectionsSuccess = (elections) => ({
  type: ACTIONS.GET_ELECTIONS_SUCCESS,
  payload: elections,
});

const getElectionsFail = () => ({
  type: ACTIONS.GET_ELECTIONS_FAIL,
});

const getRecipientsStart = () => ({
  type: ACTIONS.GET_RECIPIENTS_START,
});

const getRecipientsSuccess = (recipients) => ({
  type: ACTIONS.GET_RECIPIENTS_SUCCESS,
  payload: recipients,
});

const getRecipientsFail = () => ({
  type: ACTIONS.GET_RECIPIENTS_FAIL,
});

const getDeliveriesStart = () => ({
  type: ACTIONS.GET_DELIVERIES_START,
});

const getDeliveriesSuccess = (deliveries) => ({
  type: ACTIONS.GET_DELIVERIES_SUCCESS,
  payload: deliveries,
});

const getDeliveriesFail = () => ({
  type: ACTIONS.GET_DELIVERIES_FAIL,
});

export const resetDeliveries = () => ({
  type: ACTIONS.RESET_DELIVERIES,
});

export const resetCreateMailingStatus = () => ({
  type: ACTIONS.RESET_CREATE_MAILING_STATUS,
});

export const resetUpdateMailingStatus = () => ({
  type: ACTIONS.RESET_UPDATE_MAILING_STATUS,
});

export const resetRecipients = () => ({
  type: ACTIONS.RESET_RECIPIENTS,
});

const getMailingTemplatesStart = () => ({
  type: ACTIONS.GET_MAILINGTEMPLATES_START,
});

const getMailingTemplatesSuccess = (mailingtemplates) => ({
  type: ACTIONS.GET_MAILINGTEMPLATES_SUCCESS,
  payload: mailingtemplates,
});

const getMailingTemplatesFail = (err) => ({
  type: ACTIONS.GET_MAILINGTEMPLATES_FAIL,
  payload: err,
});

export const resetMailingTemplates = () => ({
  type: ACTIONS.RESET_MAILINGTEMPLATES,
});

const testMailingStart = () => ({
  type: ACTIONS.TEST_MAILING_START,
});

const testMailingSuccess = () => ({
  type: ACTIONS.TEST_MAILING_SUCCESS,
});

const testMailingFail = () => ({
  type: ACTIONS.TEST_MAILING_FAIL,
});

export const resetTestMailingStatus = () => ({
  type: ACTIONS.RESET_TEST_MAILING_STATUS,
});

export const setBackendStatusUp = () => ({
  type: ACTIONS.BACKEND_STATUS_CHANGED,
  payload: { status: BACKEND_STATUS.UP, downReason: null },
});

export const setBackendStatusDown = (downReason) => ({
  type: ACTIONS.BACKEND_STATUS_CHANGED,
  payload: { status: BACKEND_STATUS.DOWN, downReason },
});

//
// THUNK ACTION CREATORS
//

export const saveUser = (user) => (dispatch) => dispatch({
  type: ACTIONS.SAVE_USER,
  payload: user,
});

// Not a great way to do this, but we don't have coded responses
function lookLikeNotReadyToActivate(x) {
  const theMessage = x.message ?? '';
  return theMessage.match(API_ERROR_MESSAGE_REGEX_NOT_READY_TO_ACTIVATE);
}

/**
 * Thunk that dispatches actions indicating mail creation, and makes api call
 * to create said mailing.
 *
 * @return  {Promise}
 * Resolves into id of the newly created mailing, or rejects if failed. In the
 * case of failure, actions indicating such will be also dispatched.
 */
export const createNewMailing = (token, values) => async (dispatch) => {
  dispatch(createMailingStart());
  try {
    const { data } = await createMailing(token, values);
    dispatch(createMailingSuccess(data.id));
    // Removed push for MAIL-100. We decided to avoid having redirects as side
    // effects of thunks, and instead dispatch redirect actions at the component
    // level instead
    // dispatch(push('/mailings'));
    return data.id;
  } catch (err) {
    logger.error('createNewMailing failed', err?.response ?? 'Unknown error');

    if (err?.response?.data?.errors.some(lookLikeNotReadyToActivate)) {
      dispatch(createMailingFailNotReadyToActivate());
    } else {
      dispatch(createMailingFail());
    }
    throw err;
  }
};

/**
 * Thunk that dispatches actions indicating mail updating, and makes api call to
 * update said mailing.
 *
 * @return  {Promise}
 * Resolves (no value) on success, rejects on failure. In the case of failure,
 * actions indicating such will be also dispatched.
 */
export const updateExistingMailing = (token, id, values) => async (dispatch) => {
  dispatch(updateMailingStart());
  try {
    await updateMailing(token, id, values); // Currently the result doesn't have anything useful
    dispatch(updateMailingSuccess());
    return;
  } catch (err) {
    logger.error(err);
    logger.error('updateExistingMailing failed', err?.response ?? 'Unknown error');

    if (err?.response?.data?.errors.some(lookLikeNotReadyToActivate)) {
      dispatch(updateMailingFailNotReadyToActivate());
    } else {
      dispatch(updateMailingFail());
    }
    throw err;
  }
};

export const getMailingList = (token) => async (dispatch) => {
  dispatch(getMailingsStart());
  try {
    const result = await getMailings(token);
    dispatch(getMailingsSuccess(result));
  } catch (err) {
    logger.error('getMailingList failed', err?.response ?? 'Unknown error');
    dispatch(getMailingsFail());
  }
};

export const deleteExistingMailing = (token, mailingId) => async (dispatch) => {
  dispatch(deleteMailingStart());
  try {
    const result = await deleteMailing(token, mailingId);
    dispatch(deleteMailingSuccess(result));
  } catch (err) {
    logger.error('deleteMailing failed', err?.response ?? 'Unknown error');
    dispatch(deleteMailingFail());
  }
};

export const getSingleMailing = (token, mailingId) => async (dispatch) => {
  dispatch(getSingleMailingStart());
  try {
    const result = await getMailing(token, mailingId);
    dispatch(getSingleMailingSuccess(result));
  } catch (err) {
    logger.error('getSingleMailing failed', err?.response ?? 'Unknown error');
    dispatch(getSingleMailingFail(err));
  }
};

export const getElectionList = (token, stateCode) => async (dispatch) => {
  dispatch(getElectionsStart());
  try {
    const result = await getElections(token, stateCode);
    dispatch(getElectionsSuccess(result));
  } catch (err) {
    logger.error('getElectionList failed', err?.response ?? 'Unknown error');
    dispatch(getElectionsFail());
  }
};

export const getRecipientsList = (token, stateCode) => async (dispatch) => {
  dispatch(getRecipientsStart());
  try {
    const result = await getRecipients(token, stateCode);
    dispatch(getRecipientsSuccess(result));
  } catch (e) {
    dispatch(getRecipientsFail());
  }
};

export const getDeliveriesList = (token, stateCode) => async (dispatch) => {
  dispatch(getDeliveriesStart());
  try {
    const result = await getDeliveries(token, stateCode);
    dispatch(getDeliveriesSuccess(result));
  } catch (e) {
    dispatch(getDeliveriesFail());
  }
};

export const getMailingTemplatesList = (token) => async (dispatch) => {
  dispatch(getMailingTemplatesStart());
  try {
    const result = await getMailingTemplates(token);
    dispatch(getMailingTemplatesSuccess(result));
  } catch (err) {
    logger.error('getMailingTemplatesList failed', err?.response ?? 'Unknown error');
    dispatch(getMailingTemplatesFail(err));
  }
};

/**
 * Thunk that dispatches actions indicating mail test, and makes api call to do
 * a test send of mailing.
 *
 * @return  {Promise<{numSuccessful: number, numFailed: number}>}
 * Resolves into {numSuccessful: int, numFailed: int} on successfully sending
 * request to server, rejects on failure request failure. That is resolves even
 * if emails actually failed to go out for some reason, but server accepted
 * request.
 */
export const testTheMailing = (token, values) => async (dispatch) => {
  dispatch(testMailingStart());
  try {
    const result = await testMailing(token, values);
    dispatch(testMailingSuccess(result));
    return result;
  } catch (err) {
    logger.error('testTheMailing failed', err?.response ?? 'Unknown error');
    dispatch(testMailingFail());
    throw err;
  }
};
