/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Button, Grid, Box,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Formik } from 'formik';
import * as yup from 'yup';
import NavigationPrompt from 'react-router-navigation-prompt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import MailingSetup from './MailingSetup';
import MailingContent from './MailingContent';
import TabPanel from '../TabPanel';
import FeedbackModal from '../FeedbackModal';
import MailingFinalise from './MailingFinalise';
import { fed21Theme } from '../../theme';
import { microfrontendURLPrefix } from '../../config';

import {
  LOADING_STATUS,
  FAIL_REASON,
  EMAIL_REGEX,
  GREENS_EMAIL_REGEX,
  GREENS_PERM_SUBDOMAINS_REGEX,
} from '../../constants';

/**
 * Recursive function that identifies if there are unclosed liquidjs template tokens
 * @param {string} template - The full template body
 * @param {bool} closed - Boolean representing whether tokens are closed
 * @param {number} position - the last position a token was identified
 * @returns {bool}
 */
const areTokensClosed = (template = '', closed = true, position = 0) => {
  const next = template.indexOf(closed ? '{{' : '}}', position);
  return next < 0 ? closed : areTokensClosed(template, !closed, next);
};

const MailingSetupSchema = yup.object().shape({
  subject: yup.string().max(78, 'Too long (must be under 78 characters)').nullable(),
  name: yup.string().required('Must have a value'),
  body: yup
    .string()
    .test('body', 'There is an unclosed token in your email body', (value) => areTokensClosed(value, true))
    .test(
      'body',
      'The {{BoothRoster}} token can only be used in mailings exclusively targeting booth captains',
      function test(value) {
        return (
          !value
          || !value.includes('{{BoothRoster}}')
          || (this.options.parent.audienceTypes.length === 1
            && this.options.parent.audienceTypes[0] === 'captains')
        );
      },
    )
    .nullable(),
  senderName: yup.string().max(60, 'Too long (must be under 60 characters)'),
  senderEmail: yup
    .string()
    .matches(EMAIL_REGEX, 'Please add a valid Greens email address')
    .matches(GREENS_EMAIL_REGEX, 'Please add a valid Greens email address')
    .matches(
      GREENS_PERM_SUBDOMAINS_REGEX,
      'Please add a Greens email address with a legitimate subdomain',
    )
    .required('Please add a valid Greens email address'),
  replyEmail: yup.lazy((value) => (!value ? yup.string() : yup.string().matches(EMAIL_REGEX, 'Please add a valid email address'))),
  scheduleStart: yup.lazy((value) => (!value
    ? yup.string().nullable()
    : yup
      .string()
      .test(
        'scheduleStart',
        'Cannot start sending earlier than today',
        (date) => !date || moment().isSameOrBefore(moment(date), 'minute'),
      ))),
  scheduleStop: yup.lazy((value) => (!value
    ? yup.string().nullable()
    : yup
      .string()
      .test(
        'scheduleStop',
        'Cannot stop sending before start date',
        (date) => !date || moment().isSameOrBefore(moment(date), 'minute'),
      )
      .nullable()
      .when('scheduleStart', (scheduleStart, schema) => (scheduleStart && moment(scheduleStart).isValid()
        ? schema
          .test(
            'scheduleStop',
            'Cannot stop sending before start date',
            (date) => !date || moment(scheduleStart).isSameOrBefore(moment(date), 'minute'),
          )
          .nullable()
        : schema)))),
  scheduleOnce: yup.lazy((value) => (!value
    ? yup.string().nullable()
    : yup
      .string()
      .test(
        'scheduleOnce',
        'Cannot start sending earlier than today',
        (date) => !date || moment().isSameOrBefore(moment(date), 'minute'),
      ))),
  // cf. https://github.com/jquense/yup/issues/58 re: replyEmail
});

const MailingForm = (props) => {
  const {
    formSubmitHandler,
    mailingStatus,
    mailingFailReason,
    openOnTab,
    cleanSaveEnable,
    isNewObject,
    copyOriginalId,
    mailingId,
  } = props;
  const { formInitialValues } = props;

  const [tabValue, setTabValue] = useState(openOnTab);
  const [mailingSendOnce, setMailingSendOnce] = useState(false);
  const dispatch = useDispatch();
  const electionList = useSelector((redux) => redux.elections.electionList) ?? [];
  const tabErrorSetup = ['name', 'subject', 'senderName', 'senderEmail', 'replyEmail'];
  const tabErrorCompose = ['body'];
  const tabErrorFinalise = ['scheduleStart', 'scheduleStop', 'scheduleOnce'];
  const tabError = (errs, refs) => refs.filter((p) => errs[p]).length > 0;
  const useErrorStyles = makeStyles({
    wrapper: {
      flexDirection: 'row',
      '&::after': {
        paddingLeft: 3,
        content: "'*'",
        color: fed21Theme.palette.error.main,
      },
    },
  });
  const tabErrorClasses = useErrorStyles();

  const electionListDateMapping = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const election of electionList) {
    electionListDateMapping[election.id] = election.electionDate;
  }

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={(values, formik) => {
        formSubmitHandler(values, formInitialValues, formik, tabValue);
      }}
      validationSchema={MailingSetupSchema}
      enableReinitialize
    >
      {(formik) => (
        <>
          <Tabs
            TabIndicatorProps={{ style: { background: fed21Theme.palette.primary.main } }}
            value={tabValue}
            onChange={(_event, newValue) => {
              setTabValue(newValue);
            }}
            aria-label="Mailing form tabs"
          >
            <Tab
              label="Set up"
              value="setup"
              classes={tabError(formik.errors, tabErrorSetup) ? tabErrorClasses : {}}
            />
            <Tab
              label="Compose"
              value="content"
              classes={tabError(formik.errors, tabErrorCompose) ? tabErrorClasses : {}}
            />
            <Tab
              label="Finalise"
              value="finalise"
              classes={tabError(formik.errors, tabErrorFinalise) ? tabErrorClasses : {}}
            />
          </Tabs>
          {mailingStatus === LOADING_STATUS.FAIL && mailingFailReason === FAIL_REASON.UNKNOWN && (
            <FeedbackModal message="Could not save your mailing" />
          )}
          {mailingStatus === LOADING_STATUS.FAIL
            && mailingFailReason === FAIL_REASON.NOT_READY_TO_ACTIVATE && (
              <FeedbackModal message="Unable to save and activate, insufficient fields have been filled" />
          )}

          <Box mt={3}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <NavigationPrompt when={formik.dirty && mailingStatus === LOADING_STATUS.INIT}>
                {({ onConfirm, onCancel }) => (
                  <Dialog
                    open
                    onClose={onCancel}
                    aria-labelledby="unsaved-confirm-dialog-title"
                    aria-describedby="unsaved-confirm-dialog-description"
                    overlaystyle={{ backgroundColor: 'transparent' }}
                  >
                    <DialogTitle id="unsaved-confirm-dialog-title">Leave the page?</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="unsaved-confirm-dialog-description">
                        You have unsaved changes that will be lost if you leave the page. Do you
                        wish to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onCancel} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={onConfirm} color="primary" autoFocus>
                        Leave
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </NavigationPrompt>

              <TabPanel value={tabValue} index="setup">
                <MailingSetup
                  formik={formik}
                  isNewObject={isNewObject}
                  copyOriginalId={copyOriginalId}
                />
              </TabPanel>
              <TabPanel value={tabValue} index="content">
                <MailingContent formik={formik} />
              </TabPanel>
              <TabPanel value={tabValue} index="finalise">
                <MailingFinalise
                  formik={formik}
                  mailingStatus={mailingStatus}
                  mailingSendOnce={mailingSendOnce}
                  setMailingSendOnce={setMailingSendOnce}
                  mailingId={mailingId}
                />
              </TabPanel>
              <Box mt={3}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <Button
                      aria-label="Close"
                      type="reset"
                      variant="outlined"
                      color="secondary"
                      onClick={() => dispatch(push(microfrontendURLPrefix || '/'))}
                    >
                      Close
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      aria-label="Save"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        !isEmpty(formik.errors)
                        || (!formik.dirty && !cleanSaveEnable)
                        || mailingStatus === LOADING_STATUS.LOADING
                        || mailingSendOnce
                        || formik.isSubmitting
                        || Object.values(formik.values).every(isEmpty)
                        || (formik.values.hasStartedDeliveries && formik.values.isSendOnlyOnce)
                        || (formik.values.hasStartedDeliveries
                          && formik.values.isDailyPrepoll
                          && moment(
                            formik.values.electionDate
                              ? formik.values.electionDate
                              : formik.values.election in electionListDateMapping
                                ? electionListDateMapping[formik.values.election]
                                : '',
                          ).isSameOrBefore(moment()))
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                  {tabValue !== 'finalise' && (
                    <Grid item>
                      <Button
                        aria-label="Next"
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setTabValue(tabValue === 'setup' ? 'content' : 'finalise')}
                      >
                        Next
                        <ArrowForwardIcon />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};

const validMailingStatuses = Object.values(LOADING_STATUS);
const validMailingFailReasons = Object.values(FAIL_REASON);
const validTabValues = ['setup', 'content', 'finalise'];

MailingForm.defaultProps = {
  mailingFailReason: undefined,
  openOnTab: 'setup',
  cleanSaveEnable: false,
  isNewObject: false,
  copyOriginalId: 0,
  mailingId: '0',
};

MailingForm.propTypes = {
  formInitialValues: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    election: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    audienceTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    electorates: PropTypes.arrayOf(PropTypes.string).isRequired,
    senderName: PropTypes.string.isRequired,
    senderEmail: PropTypes.string.isRequired,
    replyEmail: PropTypes.string,
    active: PropTypes.bool.isRequired,
    hasStartedDeliveries: PropTypes.bool,
    isSendOnlyOnce: PropTypes.bool,
    mailingtemplateId: PropTypes.number,
    testEmailAddressesStr: PropTypes.string,
    testEmailAddresses: PropTypes.arrayOf(PropTypes.string),
    scheduleStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
    scheduleStop: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
    createdByName: PropTypes.string,
    createdByEmail: PropTypes.string,
    createdBySub: PropTypes.string,
    scheduleOnce: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
    isDailyPrepoll: PropTypes.bool,
    dailyPrepollTime: PropTypes.string,
    isPeriodic: PropTypes.bool,
  }).isRequired,
  formSubmitHandler: PropTypes.func.isRequired,
  mailingStatus: PropTypes.oneOf(validMailingStatuses).isRequired,
  mailingFailReason: PropTypes.oneOf(validMailingFailReasons),
  openOnTab: PropTypes.oneOf(validTabValues),
  cleanSaveEnable: PropTypes.bool,
  isNewObject: PropTypes.bool,
  copyOriginalId: PropTypes.number,
  mailingId: PropTypes.string,
};

export default MailingForm;
