/* eslint-disable no-console */

import { environment as environmentFromConfig } from '../config';

const ENVIRONMENTS_TO_PRINT = {
  error: ['prod', 'dev', 'local'],
  warn: ['prod', 'dev', 'local'],
  info: ['prod', 'dev', 'local'],
  debug: ['dev', 'local'],
  log: ['local'], // log isn't really useful. also logger.log looks weird :)
};

export class Logger {
  doPrint = {};

  /**
   * @param   {string}  environment  'dev', 'prod', 'local' or 'test'
   *
   * @return  {void}
   */
  constructor(environment) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [verb, environments] of Object.entries(ENVIRONMENTS_TO_PRINT)) {
      this.doPrint[verb] = environments.includes(environment);
    }
  }

  log(...args) {
    if (this.doPrint.log) {
      console.log(...args);
    }
  }

  debug(...args) {
    if (this.doPrint.debug) {
      console.debug(...args);
    }
  }

  error(...args) {
    if (this.doPrint.error) {
      console.error(...args);
    }
  }

  warn(...args) {
    if (this.doPrint.warn) {
      console.warn(...args);
    }
  }

  info(...args) {
    if (this.doPrint.info) {
      console.info(...args);
    }
  }
}

export default new Logger(environmentFromConfig);
