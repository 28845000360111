import '../css/fed2021.css';

// This function is used by react-draft-wysiwyg in the MailingContent
// component to render block styles in the editor according to
// our brand requirements

// It is separated out here so we don't have to touch the MailingContent
// component directly whenever our brand requirements change

export default function greensBlockStyleFn(contentBlock) {
  const type = contentBlock.getType();
  switch (type) {
    case 'header-one':
      return 'fed21-h1';
    case 'header-two':
      return 'fed21-h2';
    case 'header-three':
      return 'fed21-h3';
    case 'unstyled':
    case 'paragraph':
      return 'fed21-p';
    default:
      return null;
  }
}
