import React, { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { fed21Theme } from '../../../theme';

export const defaultIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <div {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const defaultOptions = {
  draggable: false,
  toolbar: false,
  filtering: true,
  sorting: true,
  rowStyle: {
    fontFamily: fed21Theme.typography.fontFamily,
    ...fed21Theme.typography.body2,
  },
  loadingType: 'overlay', // Not actually made use of, we use skeletons rows
  paginationType: 'normal', // Other possible value is 'stepped'
};

export const defaultLocalization = (emptyTableMessage) => ({
  body: {
    emptyDataSourceMessage: emptyTableMessage,
  },
});

function generateSkeletonColumns(origColumns) {
  return origColumns.map((x) => ({
    ...x,
    render: () => (<Skeleton variant="text" />),
  }));
}

function generateSkeletonData(pageSize) {
  // We can't use data.fill() because each row has to reference a separate
  // object, and material-table somehow uses that to create component keys for
  // each row and cell
  const data = [];
  data.length = pageSize;
  for (let i = 0; i < pageSize; i += 1) data[i] = {};
  return data;
}

/**
 * A basic wrapper around MaterialTable component, styled the way we want it
 * across the app by default: The draggable and toolbar options are set to
 * false, while filtering is true.
 *
 * The emptyTableMessage prop can be used to control what is displayed when the
 * table is empty.
 *
 * The containerTestId will set the data-testid on the outer container. Note
 * that this elements then containers two tables: one for the upper body and one
 * for the footer containing the pagination widgets.
 *
 * If options prop is provided, it will be combined with the default to allow
 * overriding individual options, such as the pageSize.
 *
 * The defaults for other props can' be overridden, since there was no need at
 * when this was created.
 */
const RosterMailerTable = ({
  emptyTableMessage, containerTestId, columns, data, isLoading, options, ...rest
}) => {
  // To address the issue discovered in MAIL-176 Also raised as bug on library
  // https://github.com/material-table-core/core/issues/200 We make a copy of
  // each of the elements in the columns prop, because otherwise they will be
  // mutated, which in our case is not the intention and it leads to retaining
  // state between mounts.
  //
  // We don't need to worry anything deeper than the elements themselves (at
  // least I assume that is the case) so we can just use a simple map. In the
  // case of isLoading being true, we make a copy of fake columns anyway
  // containing skeletons.
  const theColumnsAsCopy = isLoading
    ? generateSkeletonColumns(columns)
    : columns.map((x) => ({ ...x }));

  const theData = isLoading
    ? generateSkeletonData(options?.pageSize ?? defaultOptions.pageSize)
    : data;

  return (
    <MaterialTable
      columns={theColumnsAsCopy}
      data={theData}
      isLoading={isLoading}
      icons={defaultIcons}
      options={{ ...defaultOptions, ...options }}
      localization={defaultLocalization(emptyTableMessage)}
      components={{
        Container: (props) => (<Paper data-testid={containerTestId} {...props} />),
        OverlayLoading: () => <></>, // We use fake column with skeletons instead
      }}
      {...rest}
    />
  );
};

RosterMailerTable.defaultProps = {
  emptyTableMessage: 'No records to display',
  containerTestId: undefined,
  options: {}, // Since will be combined with the actual defaults
};

RosterMailerTable.propTypes = {
  emptyTableMessage: PropTypes.string,
  containerTestId: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      currencySetting: PropTypes.shape({
        locale: PropTypes.string,
        currencyCode: PropTypes.string,
        minimumFractionDigits: PropTypes.number,
        maximumFractionDigits: PropTypes.number,
      }),
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.func,
  ]).isRequired,
  // Not 100% certain using best way of overriding default props, so no point
  // spending time defining this in detail
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

export default RosterMailerTable;
